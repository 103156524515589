import { FromSchema, JSONSchema } from "json-schema-to-ts";
import { AppSqlApi } from "../../data/app-sql-api";
import { defaultIdName } from "../../data/conflict-free-db/core";
import { DeleteRowsOp, UpsertTableDataOp } from "../../data/conflict-free-db/operations";
import { VersionedGptMessage, getVersionedGptMessage } from "./gpt-types";
import { createTableOp, makeValidator, rowToNative } from "./schema";

export const dbChatMessagesSchema = {
  title: "DbChatMessages",
  type: "object",
  properties: {
    [defaultIdName]: {
      type: "string",
    },
    objectId: {
      type: "string",
    },
    gptMessage: {
      type: "string"
    }
  },
  additionalProperties: false,
  required: [defaultIdName],
} as const satisfies JSONSchema;

export type DbChatMessage = FromSchema<typeof dbChatMessagesSchema>;
const isDbChatMessage = makeValidator<DbChatMessage>(dbChatMessagesSchema);
const dbChatMessagesRowToNative = rowToNative(dbChatMessagesSchema);

export interface ChatMessage {
  id: string;
  objectId: string;
  gptMessage: VersionedGptMessage;
}

export class DbChatMessages {
  constructor(private db: AppSqlApi, readonly tableName = "chat_messages") {}
  initialize() {
    const createTable = createTableOp(this.tableName, dbChatMessagesSchema);
    this.db.applyLocalOp(createTable);
    this.db.queryShared(
      `CREATE INDEX IF NOT EXISTS ${this.tableName}_objectId ON ${this.tableName}(objectId)`
    );
  }
  deleteMessage(id: string) {
    this.db.applyLocalOp(new DeleteRowsOp(this.tableName, [id]));
  }
  addMessage(message: ChatMessage) {
    this.db.applyLocalOp(new UpsertTableDataOp(this.tableName, {
      [message.id]: {
        [defaultIdName]: message.id,
        objectId: message.objectId,
        gptMessage: JSON.stringify(message.gptMessage),
      }
    }));
  }
  getMessagesForObjectId(objectId: string): ChatMessage[] {
    const cols = ["id", "objectId", "gptMessage"];
    const [results] = this.db.queryShared(
      `SELECT ${cols.join(", ")} FROM ${this.tableName} WHERE objectId = ?`,
      [objectId]
    );
    if (!results) {
      return [];
    }
    const items: ChatMessage[] = [];
    for (const row of results.values) {
      const msg = this.rowToChatMessage(cols, row);
      if (msg) {
        items.push(msg);
      }
    }
    return items;
  }
  private rowToChatMessage(cols: string[], row: any[]): ChatMessage | undefined {
    const object = dbChatMessagesRowToNative(cols, row);
    if (typeof object.gptMessage !== "string") {
      return;
    }
    const gptMessage = getVersionedGptMessage(object.gptMessage);
    if (isDbChatMessage(object) && object.objectId && gptMessage) {
      return {
        id: object[defaultIdName],
        objectId: object.objectId,
        gptMessage,
      }
    } else {
      return undefined;
    }
  }
}
