import { Alert, Box, Tab, Tabs, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useLocation } from "react-router-dom";
import { FpApi, ObjectDetails } from "../fp-api";
import { useObjectNavigate } from "../notes-hooks";
import { ViewRenderer } from "./ViewRenderer";

export function ObjectDetailTabs({
  object,
  api,
}: {
  object: ObjectDetails;
  api: FpApi;
}) {
  const id = object.id;
  const [tab, setTab] = useState("tags");
  const location = useLocation();
  const hash = location.hash.replace("#", "");
  const navigate = useObjectNavigate();
  useEffect(
    () => setTab(hash || object.defaultViewId),
    [id, hash, object.defaultViewId]
  );
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 1 }}>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={tab}
          onChange={(_, value) => navigate(id, value)}
          TabIndicatorProps={{
            style: { transition: "none" },
          }}
        >
          {object.views.map((view) => (
            <StyledTab key={view.id} label={view.title} value={view.id} />
          ))}
        </Tabs>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          overflow: "hidden",
        }}
      >
        <ErrorBoundary FallbackComponent={Fallback}>
          {object.views.map((view) => (
            <TabPanel key={view.id} value={tab} id={view.id}>
              <ViewRenderer object={object} api={api} view={view} />
            </TabPanel>
          ))}
        </ErrorBoundary>
      </Box>
    </>
  );
}

const StyledTab = styled(Tab)`
  text-transform: none;
`;

function Fallback({ error }: { error: Error }) {
  return <Alert severity="error">{error.message}</Alert>;
}

interface TabPanelProps {
  children?: React.ReactNode;
  id: any;
  value: any;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, id, ...other } = props;
  const hidden = value !== id;
  return (
    <div
      role="tabpanel"
      hidden={hidden}
      id={`simple-tabpanel-${id}`}
      aria-labelledby={`simple-tab-${id}`}
      style={{
        display: hidden ? "none" : "flex",
        flexDirection: "column",
        flexGrow: 1,
        overflow: "scroll",
      }}
      {...other}
    >
      {value === id && children}
    </div>
  );
}
