import { SourceRecord, SourceStateDb } from "./app-db";
import {
  addSource,
  SourceAction,
  SourceState,
  SourceType,
} from "./source-state";

export interface SourceStore {
  get(id: string): Promise<SourceState | undefined>;
  getByName(name: string): Promise<string[]>;
  getByType(type: SourceType): Promise<StoreData>;
  apply(id: string, action: SourceAction): Promise<void>;
  list(): Promise<string[]>;
}

export const defaultSourceId = "default";

export type StoreData = { [id: string]: SourceState };
export class SimpleSourceStore implements SourceStore {
  private data: StoreData = {};
  constructor(data?: StoreData | undefined) {
    this.data = data ?? {};
  }
  async apply(key: string, action: SourceAction): Promise<void> {
    const current = this.data[key];
    const next = action(current);
    this.data[key] = next;
  }
  async get(key: string): Promise<SourceState | undefined> {
    return this.data[key];
  }
  async getByType(type: SourceType): Promise<StoreData> {
    const entries = Object.entries(this.data).filter(
      ([_key, val]) => val.type === type
    );
    return Object.fromEntries(entries);
  }
  async getByName(name: string) {
    const results: string[] = [];
    for (const [id, source] of Object.entries(this.data)) {
      if (source.name === name) {
        results.push(id);
      }
    }
    return results;
  }
  async list() {
    return Object.keys(this.data);
  }
}

export class DbSourceStore implements SourceStore {
  private db: SourceStateDb;
  constructor(db: SourceStateDb) {
    this.db = db;
  }
  async get(key: string): Promise<SourceState | undefined> {
    const [object] = await this.db.sourceState.where({ id: key }).toArray();
    if (!object) {
      return object;
    }
    const unwrapped = unwrap(object);
    return unwrapped;
  }
  async getByType(type: SourceType): Promise<StoreData> {
    const records = await this.db.sourceState.where({ type }).toArray();
    return Object.fromEntries(records.map((r) => [r.id, unwrap(r)]));
  }
  async getByName(name: string): Promise<string[]> {
    const objects = await this.db.sourceState.toArray();
    const results: string[] = [];
    for (const record of objects) {
      if (record.name === name && !record.isDeleted) {
        results.push(record.id);
      }
    }
    return results;
  }
  async apply(key: string, action: SourceAction): Promise<void> {
    const db = this.db;
    return db.transaction("readwrite", db.sourceState, async () => {
      const current = await db.sourceState.get(key);
      const next = action(current);
      await db.sourceState.put({ ...next, id: key }, key);
    });
  }
  async list(): Promise<string[]> {
    const keys = await this.db.sourceState.toCollection().keys();
    const results = [];
    for (const k of keys) {
      if (typeof k === "string") {
        results.push(k);
      }
    }
    return results;
  }
}

function unwrap(record: SourceRecord): SourceState {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...rest } = record;
  return rest;
}

export async function initializeSources(store: SourceStore) {
  const existing = await store.get(defaultSourceId);
  if (existing) {
    return;
  }
  const add = addSource("Default", SourceType.default, undefined);
  await store.apply(defaultSourceId, add);
}
