import {
  Box,
  Drawer,
  ThemeProvider,
  createTheme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import React, { createContext, useEffect, useRef, useState } from "react";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import { DefaultAppBar } from "./DefaultAppBar";
import { MainMenu } from "./MainMenu";
import { NoteSearchAppBar } from "./notes/search-bar/NoteSearchAppBar";

export interface TopBarState {
  searchIsOpen: boolean;
  setSearchIsOpen: (value: boolean) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
}
export const TopBarContext = createContext<TopBarState>({
  searchIsOpen: false,
  setSearchIsOpen: () => {
    return;
  },
});
export const SourceContext = createContext<string | undefined>(undefined);
const theme = createTheme();
const drawerWidth = 240;

function MobileMenuDrawer({
  isOpen,
  toggle,
  setSourceId,
}: {
  isOpen: boolean;
  toggle: () => void;
  setSourceId: (value: string) => void;
}) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const container = () => window.document.body;
  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="menu"
    >
      <Drawer
        container={container}
        variant="temporary"
        open={isOpen && matches}
        onClose={toggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        <div onClick={toggle}>
          <MainMenu setSourceId={setSourceId} />
        </div>
      </Drawer>
    </Box>
  );
}

function Top({ toggleMenu }: { toggleMenu: () => void }) {
  return (
    <Routes>
      <Route
        path="notes/*"
        element={<NoteSearchAppBar onMenuClick={toggleMenu} />}
      />
      <Route path="*" element={<DefaultAppBar onMenuClick={toggleMenu} />} />
    </Routes>
  );
}

function Bottom({
  setSourceId,
  showMenu,
}: {
  showMenu: boolean;
  setSourceId: (value: string) => void;
}) {
  const ref = useRef<HTMLDivElement | null>(null);
  const { pathname } = useLocation();
  useEffect(() => {
    ref.current?.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        height: "100%",
        overflow: "hidden",
      }}
    >
      {showMenu && (
        <div style={{ minWidth: drawerWidth }}>
          <MainMenu setSourceId={setSourceId} />
        </div>
      )}
      <div
        ref={ref}
        style={{
          display: "flex",
          overflowY: "auto",
          flexGrow: 1,
          height: "100%",
          overflowX: "hidden",
        }}
      >
        <Outlet />
      </div>
    </div>
  );
}

export function Layout() {
  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const [sourceId, setSourceId] = useState<string | undefined>();
  const inputRef = useRef<HTMLInputElement>(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <TopBarContext.Provider
        value={{ searchIsOpen, setSearchIsOpen, inputRef }}
      >
        <SourceContext.Provider value={sourceId}>
          <Top toggleMenu={handleDrawerToggle} />
          {isMobile && (
            <MobileMenuDrawer
              isOpen={mobileOpen}
              setSourceId={setSourceId}
              toggle={handleDrawerToggle}
            />
          )}
          <Bottom showMenu={!isMobile} setSourceId={setSourceId} />
        </SourceContext.Provider>
      </TopBarContext.Provider>
    </ThemeProvider>
  );
}
