import { GDriveCrawlerDb } from "../app-db";

export interface GDriveCrawlerStore {
  getNextPageToken(id: string): Promise<string | undefined>;
  setNextPageToken(id: string, token: string | undefined): Promise<void>;
}

export class SimpleCrawlerStore implements GDriveCrawlerStore {
  private data: { [id: string]: string | undefined } = {};
  async getNextPageToken(id: string) {
    return this.data[id];
  }
  async setNextPageToken(id: string, token: string | undefined): Promise<void> {
    this.data[id] = token;
  }
}

export class DbGDriveCrawlerStore implements GDriveCrawlerStore {
  private db: GDriveCrawlerDb;
  constructor(db: GDriveCrawlerDb) {
    this.db = db;
  }
  async getNextPageToken(id: string): Promise<string | undefined> {
    const record = await this.db.gdriveCrawler.get(id);
    return record?.nextPageToken;
  }
  async setNextPageToken(id: string, token: string | undefined): Promise<void> {
    const record = { id, nextPageToken: token };
    await this.db.gdriveCrawler.put(record);
  }
}

export function initializeCrawlerStore(db: GDriveCrawlerDb) {
  return new DbGDriveCrawlerStore(db);
}
