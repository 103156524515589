import {
  Box,
  Button,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popper,
  Slide,
  useTheme,
} from "@mui/material";
import React, { useCallback, useLayoutEffect, useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { DefaultAppBar } from "../../DefaultAppBar";
import { LayoutAppBar, appBarZ } from "../../LayoutAppBar";
import { RequireSource } from "../../RequireSource";
import { SearchBar } from "../../SearchBar";
import { useTopBar } from "../../hooks";
import { ClipboardText, useClipboard } from "../ClipboardButtons";
import { ListViewContent, useListViewState } from "../ListView";
import {
  useAddNote,
  useDbQueries,
  useFpApi,
  useObjectNavigate,
} from "../notes-hooks";
import { QuickHitItem, QuickHits } from "./QuickHits";

export function NoteSearchAppBar({ onMenuClick }: { onMenuClick: () => void }) {
  return (
    <RequireSource sourceMissing={<DefaultAppBar onMenuClick={onMenuClick} />}>
      <BaseNoteSearchAppBar onMenuClick={onMenuClick} />
    </RequireSource>
  );
}

const pageSize = 100;
function BaseNoteSearchAppBar({ onMenuClick }: { onMenuClick: () => void }) {
  const api = useFpApi();
  const navigate = useObjectNavigate();
  const appBarEl = useRef(null);
  const queries = useDbQueries();
  const getObjects = useCallback(
    (query: string, page: number) =>
      queries.searchObjects(query, pageSize, pageSize * page),
    [queries]
  );
  const { objects, setInput, input, loadNextPage, selection } =
    useListViewState(getObjects);
  const clipboardUrl = useClipboardUrl();
  const quickHits: QuickHitItem[] = [];
  const existing = queries.getObjectsWithLink(clipboardUrl);
  for (const existingObj of existing) {
    quickHits.push({ type: "object", object: existingObj });
  }
  if (existing.length === 0 && input.trim()) {
    quickHits.push({
      type: "create-note",
      note: { actionTitle: "Add", noteTitle: input, content: "" },
    });
  }
  if (existing.length === 0 && clipboardUrl) {
    let url: string;
    try {
      const parsed = new URL(clipboardUrl);
      url = clipboardUrl.replace(parsed.protocol + "//", "");
      if (url.startsWith("www.")) {
        url = url.replace("www.", "");
      }
    } catch {
      url = clipboardUrl;
    }
    const totalMax = 55;
    const minUrl = Math.min(url.length, 20);
    const remaining = totalMax - minUrl;
    let textPart: string;
    if (input.length > remaining - 1) {
      textPart = input.substring(0, remaining - 3) + "...";
    } else {
      textPart = input;
    }
    const actionPreviewText = `${textPart} ${url}`;
    quickHits.push({
      type: "create-note",
      note: {
        actionPreviewText,
        actionTitle: "Paste URL",
        noteTitle: input,
        content: clipboardUrl,
      },
    });
  }
  const starred = queries.searchStarredObjects(input, 10);
  for (const starredObj of starred) {
    quickHits.push({ type: "object", object: starredObj });
  }

  const {
    searchIsOpen: isOpen,
    setSearchIsOpen: setIsOpen,
    inputRef: topBarInputRef,
  } = useTopBar();
  const thisInputRef = useRef<HTMLInputElement>(null);
  const inputRef = topBarInputRef ?? thisInputRef;
  const clear = () => {
    setInput("");
    setIsOpen(false);
    selection.clear();
    if (inputRef.current) {
      inputRef.current?.blur();
    }
  };
  const addNote = useAddNote();
  const add = (value: string) => {
    const id = addNote(value);
    navigate(id);
    clear();
  };
  useHotkeys("/", (e) => {
    if (!inputRef.current) {
      return;
    }
    setIsOpen(true);
    inputRef.current.focus();
    e.preventDefault();
  });
  useHotkeys("esc", () => clear(), { enableOnFormTags: ["input"] });
  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, [isOpen]);
  const theme = useTheme();
  return (
    <>
      <LayoutAppBar onMenuClick={onMenuClick} ref={appBarEl}>
        <Box sx={{ flexGrow: 1 }}>
          <SearchBar
            onClick={() => setIsOpen(true)}
            onChange={(value) => {
              setInput(value);
              selection.clear();
            }}
            onEnter={() => {
              if (isOpen && selection.index === undefined) {
                add(input);
                clear();
              }
            }}
            value={input}
            inputRef={inputRef}
          />
        </Box>
        <Collapse orientation="horizontal" in={isOpen}>
          <Button sx={{ ml: 2 }} color="inherit" onClick={clear}>
            Cancel
          </Button>
        </Collapse>
      </LayoutAppBar>
      <Popper
        open={isOpen}
        anchorEl={appBarEl.current}
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
        sx={{
          zIndex: appBarZ - 1,
          width: "100%",
          height: "100%",
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Slide {...TransitionProps} direction="down">
            <Box
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor: theme.palette.background.default,
              }}
            >
              <QuickHits items={quickHits} onNavigate={clear} />
              <ListViewContent
                selection={selection}
                onSelect={(object) => {
                  clear();
                  if (input.trim()) {
                    api.addSearchTerm(object.id, input.trim());
                  }
                  navigate(object.id);
                }}
                getPrimaryText={(object) => object.title}
                getSecondaryText={(object) => object.text}
                objects={objects}
                loadNextPage={loadNextPage}
                input={input}
                noHits={(query) => (
                  <List>
                    <ListItem>
                      <ListItemButton onClick={() => add(input)}>
                        <ListItemText>
                          <AddMessage query={query} />
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                    {clipboardUrl && (
                      <ListItem>
                        <ListItemButton onClick={() => add(clipboardUrl)}>
                          <ListItemText>
                            <AddMessage query={clipboardUrl} />
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                    )}
                  </List>
                )}
              />
            </Box>
          </Slide>
        )}
      </Popper>
    </>
  );
}

function AddMessage({ query }: { query: string }) {
  const label = query ? (
    <>Create &ldquo;{query}&rdquo;</>
  ) : (
    <>Create a new note</>
  );
  return label;
}

function useClipboardUrl() {
  const clipboard = useClipboard();
  const clipboardText = clipboard.items.find(
    (item): item is ClipboardText => item.type === "text"
  );
  if (!clipboardText) {
    return;
  }
  const value = clipboardText.value.trim();
  if (!value?.startsWith("http")) {
    return;
  }
  return value;
}
