import {
  BugReport,
  Delete,
  Folder,
  Home,
  Note,
  Shuffle,
  Star,
  Sync,
  Warning,
} from "@mui/icons-material";
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getController } from "../data/controller";
import { OnlineStatus } from "./OnlineStatus";
import { SourceSelector } from "./SourceSelector";

interface NavListItemProps {
  title: string;
  link: string;
  icon?: React.ReactNode;
}
function NavListItem({ title, link, icon }: NavListItemProps) {
  return (
    <ListItem disableGutters>
      <ListItemButton component={Link} to={link}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText>{title}</ListItemText>
      </ListItemButton>
    </ListItem>
  );
}

export function MainMenu({
  setSourceId,
}: {
  setSourceId: (sourceId: string) => void;
}) {
  return (
    <List>
      <NavListItem title="Home" link="/" icon={<Home />} />
      <NavListItem title="Notes" link="/notes" icon={<Note />} />
      <NavListItem
        title="Starred Notes"
        link="/notes/starred"
        icon={<Star />}
      />
      <NavListItem
        title="Random Notes"
        link="/notes/random"
        icon={<Shuffle />}
      />
      <NavListItem
        title="Orphaned Notes"
        link="/notes/orphaned"
        icon={<Warning />}
      />
      <NavListItem
        title="Trashed Notes"
        link="/notes/trashed"
        icon={<Delete />}
      />
      <NavListItem title="Sync" link="/notes/sync" icon={<Sync />} />
      <Divider />
      <NavListItem title="Sources" link="/sources" icon={<Folder />} />
      <NavListItem title="Debug" link="/debug" icon={<BugReport />} />
      <AuthButton />
      <ListItem>
        <SourceSelector onChange={setSourceId} />
      </ListItem>
      <ListItem>
        <OnlineStatus />
      </ListItem>
    </List>
  );
}

function AuthButton() {
  const [_pending, setPending] = useState(false);
  const controller = getController();
  async function handleClick() {
    try {
      setPending(true);
      await controller.refreshAuth();
    } finally {
      setPending(false);
    }
  }
  return <ListItemButton onClick={handleClick}>Refresh Auth</ListItemButton>;
}
