import Graph from "graphology";
import louvain from "graphology-communities-louvain";
import { toUndirected } from "graphology-operators";

interface GraphData {
  nodes: Node[];
  edges: Edge[];
}

interface Node {
  id: string;
}

interface Edge {
  id: string;
  from: string;
  to: string;
}

type Clustering = { [id: string]: number };

export function cluster(graphData: GraphData): Clustering {
  const graph = new Graph();
  for (const node of graphData.nodes) {
    graph.addNode(node.id);
  }
  for (const edge of graphData.edges) {
    graph.addEdge(edge.from, edge.to);
  }
  const communities = louvain(toUndirected(graph));
  return communities;
}
