import { FileData, FileMetadata, md5 } from "../file-state";

export type GoogleDriveMetadata = GDriveCreateMetadata & {
  readonly md5Checksum: string;
  readonly trashed: boolean;
  readonly parents?: string[];
  readonly createdTime?: string;
  readonly modifiedTime?: string;
};

export interface GDriveCreateMetadata {
  readonly id: string;
  readonly name: string;
  readonly mimeType: string;
}

export interface GDriveUpdateMetadata {
  readonly name: string;
  readonly mimeType: string;
  readonly trashed: boolean;
}

export function toFileMetadata(metadata: GoogleDriveMetadata): FileMetadata {
  return {
    name: metadata.name,
    isDeleted: metadata.trashed,
    contentType: metadata.mimeType,
    modifiedAt: new Date(metadata.modifiedTime ?? 0).getTime(),
    createdAt: new Date(metadata.createdTime ?? 0).getTime(),
    md5Checksum: metadata.md5Checksum,
    parent:
      metadata.parents && metadata.parents.length
        ? metadata.parents[0]
        : undefined,
  };
}

export function toWriteable(
  metadata: GoogleDriveMetadata
): GDriveCreateMetadata {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { md5Checksum, trashed, ...rest } = metadata;
  return rest;
}

export function toUpdateable(
  metadata: GoogleDriveMetadata
): GDriveUpdateMetadata {
  const { name, mimeType, trashed } = metadata;
  return { name, mimeType, trashed };
}

export function gdriveMetadata(
  id: string,
  data: FileData
): GoogleDriveMetadata {
  const { contentType, name, isDeleted, parent } = data.metadata;
  const md5Checksum = md5(data.content);
  return {
    id,
    name,
    mimeType: contentType,
    trashed: isDeleted,
    md5Checksum,
    parents: parent ? [parent] : [],
  };
}
