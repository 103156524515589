import OpenAI from "openai";
import { DbQueries } from "./db-queries";
import { ObjectDetails } from "./fp-api";

type GptMessage = OpenAI.Chat.ChatCompletionMessageParam;
type GptFunctions =
  OpenAI.Chat.ChatCompletionCreateParamsNonStreaming["functions"];
type GptFunction = OpenAI.Chat.Completions.ChatCompletionCreateParams.Function;
export type GptRequest = OpenAI.Chat.ChatCompletionCreateParamsNonStreaming;
type GptModel = GptRequest["model"];

const sqlite3: GptFunction = {
  name: "sqlite3",
  description: "Execute a sqlite command or query",
  parameters: {
    type: "object",
    properties: {
      query: {
        type: "string",
        description: "The command or query to execute",
      },
    },
    required: ["query"],
  },
};

interface GptSqlOptions {
  readonly apiKey: string;
  readonly object: ObjectDetails;
  readonly dbQueries: DbQueries;
  readonly prompt: string;
}
export class GptSql {
  private gpt: Gpt;
  constructor(private opts: GptSqlOptions) {
    this.gpt = new Gpt(opts.apiKey);
  }
  async getSql(query: string) {
    const message = await this.getCompletion(query);
    const sql = await this.parseResponse(message);
    return sql;
  }

  private async getCompletion(query: string) {
    const schema = this.opts.dbQueries.schema();
    const messages: GptMessage[] = [
      {
        role: "system",
        content: `Convert the user's message to SQLite3 query. Here's the schema\n: ${schema}\n\nHere is extra guidance:\n${this.opts.prompt}`,
      },
      { role: "user", content: query },
    ];
    log("system:", messages[0].content);
    log("user:", messages[1].content);
    const message = await this.gpt.getCompletion({
      messages,
      functions: [sqlite3],
      functionName: "sqlite3",
    });
    log(message);
    return message;
  }

  private async parseResponse(message: GptMessage) {
    // @ts-ignore
    if (!message.function_call) {
      throw new Error("Expected function call");
    }
    // @ts-ignore
    const args = JSON.parse(message.function_call.arguments);
    if (typeof args?.query !== "string") {
      throw new Error("Expected query");
    }
    return args.query;
  }
}

interface CompletionReq {
  messages: GptMessage[];
  functions: GptFunctions;
  functionName?: string;
}
class Gpt {
  private openAi: OpenAI;

  constructor(apiKey: string, private model: GptModel = "gpt-4") {
    this.openAi = new OpenAI({
      dangerouslyAllowBrowser: true,
      apiKey,
    });
  }

  async getCompletion({
    messages,
    functions,
    functionName,
  }: CompletionReq): Promise<GptMessage> {
    const function_call = functionName ? { name: functionName } : undefined;
    const req: GptRequest = {
      messages,
      functions,
      function_call,
      model: this.model,
    };
    const resp = await this.openAi.chat.completions.create(req);
    return resp.choices[0].message;
  }
}

function log(...args: any[]) {
  // eslint-disable-next-line no-console
  console.log(...args);
}
