import { TextField, useTheme } from "@mui/material";
import React, {
  CSSProperties,
  FocusEvent,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";

export function InlineEdit({
  defaultValue,
  onSave,
  onFocus,
  fontSize,
  placeholder,
  saveOnEnter,
  inputRef,
  label,
}: {
  label?: string;
  defaultValue?: string;
  placeholder?: string;
  onSave: (value: string) => void;
  fontSize?: CSSProperties;
  saveOnEnter?: boolean;
  onFocus?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  inputRef?: RefObject<HTMLInputElement | HTMLTextAreaElement>;
}) {
  const theme = useTheme();
  const [value, setValue] = useState("");
  const defaultRef = useRef<HTMLElement>(null);
  const ref = inputRef ?? defaultRef;
  useEffect(() => setValue(defaultValue ?? ""), [defaultValue]);
  return (
    <TextField
      label={label}
      variant="standard"
      margin="normal"
      value={value}
      spellCheck={false}
      onChange={(e) => setValue(e.target.value)}
      onBlur={() => {
        if (value !== defaultValue) {
          onSave(value);
        }
      }}
      onKeyDown={(e) => {
        if (saveOnEnter && e.key === "Enter") {
          e.preventDefault();
          ref.current?.blur();
        } else if (e.key === "Escape") {
          ref.current?.blur();
        }
      }}
      fullWidth
      inputRef={ref}
      placeholder={placeholder}
      InputProps={{
        sx: {
          fontSize: fontSize ?? theme.typography.h5,
          textOverflow: "",
        },
        disableUnderline: true,
        onFocus: (e) => onFocus && onFocus(e),
      }}
      multiline
    />
  );
}
