import { AppDb, LogRecord } from "./app-db";
import { DbLogStore, LogStore } from "./log-store";

export interface LogEntry {
  timestamp: Date;
  message: string;
}

export type Logger = (message: string) => void;
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const defaultLogger: Logger = () => {};

export function prefix(logger: Logger, value: string): Logger {
  return function (message: string) {
    logger(`${value} ${message}`);
  };
}

export class LogController {
  private store: LogStore;
  constructor(store: LogStore) {
    this.store = store;
  }
  async getLog(): Promise<LogRecord[]> {
    return this.store.list();
  }
  async clearLog() {
    return this.store.clear();
  }
  getLogger() {
    return (message: string) => this.store.append(message);
  }
}

let singleton: LogController;
export function initializeLogController(db: AppDb) {
  if (singleton) {
    throw new Error("log controller already initialized");
  }
  const store = new DbLogStore(db);
  singleton = new LogController(store);
  return singleton;
}

export function getLogController() {
  if (!singleton) {
    throw new Error("log controller not initialized");
  }
  return singleton;
}

export function makeTimer(logger: Logger) {
  const wrapped = prefix(logger, "[Timing]");
  return async function <T>(name: string, fn: () => Promise<T>) {
    const now = performance.now();
    const result = await fn();
    const delta = performance.now() - now;
    wrapped(`[${delta.toFixed(0)}ms] ${name}`);
    return result;
  };
}
