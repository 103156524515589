import { LogStore } from "../log-store";
import { PeriodicBackgroundJob } from "./background-jobs";

export const logCleanupJob = (logStore: LogStore, limitSeconds = 60 * 60) => {
  return new PeriodicBackgroundJob({
    name: "log-cleanup",
    delayMs: 10000,
    job: async () => {
      return logStore.cleanup(limitSeconds);
    },
  });
};
