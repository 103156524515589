import { FromSchema } from "json-schema-to-ts";
import { SqlValue } from "sql.js";
import { AppSqlApi } from "../../data/app-sql-api";
import { defaultIdName } from "../../data/conflict-free-db/core";
import { DeleteRowsOp } from "../../data/conflict-free-db/operations";
import { DbObjects } from "./db-objects";
import { createTableOp, makeValidator, rowToNative, upsert } from "./schema";

export const dbTagSchema = {
  title: "DbTag",
  type: "object",
  properties: {
    [defaultIdName]: {
      type: "string",
    },
    objectId: {
      type: "string",
    },
    tagObjectId: {
      type: "string",
    },
  },
  additionalProperties: false,
  required: [defaultIdName],
} as const;
export type DbTag = FromSchema<typeof dbTagSchema>;

const dbTagRowToNative = rowToNative(dbTagSchema);
const isDbTag = makeValidator<DbTag>(dbTagSchema);
export function initializeTags(tableName: string, db: AppSqlApi) {
  const createTable = createTableOp(tableName, dbTagSchema);
  db.applyLocalOp(createTable);
  db.queryShared(
    `CREATE INDEX IF NOT EXISTS ${tableName}_objectId ON ${tableName}(objectId)`
  );
  db.queryShared(
    `CREATE INDEX IF NOT EXISTS ${tableName}_tagObjectId ON ${tableName}(tagObjectId)`
  );
}
export interface TagListItem {
  readonly id: string;
  readonly tagTitle: string;
  readonly tagObjectId?: string;
}
export interface TagOfListItem {
  readonly id: string;
  readonly objectTitle: string;
  readonly objectId?: string;
}
export class DbTags {
  constructor(
    private db: AppSqlApi,
    private dbObjects: DbObjects,
    readonly tableName = "tags"
  ) {}
  initialize() {
    initializeTags(this.tableName, this.db);
  }
  add(tag: DbTag) {
    this.upsert(tag);
  }
  delete(id: string) {
    const op = new DeleteRowsOp(this.tableName, [id]);
    this.db.applyLocalOp(op);
  }
  deleteWhere(objectId: string, tagObjectId: string) {
    const [results] = this.db.queryShared(
      `SELECT * FROM ${this.tableName} WHERE objectId = ? AND tagObjectId = ?`,
      [objectId, tagObjectId]
    );
    if (!results) {
      return;
    }
    for (const row of results.values) {
      const tag = this.rowToTag(results.columns, row);
      if (tag) {
        this.delete(tag.id);
      }
    }
  }
  get(id: string) {
    const [results] = this.db.queryShared(
      `SELECT * FROM ${this.tableName} WHERE id = ?`,
      [id]
    );
    if (!results || results.values.length === 0) {
      return;
    }
    return this.rowToTag(results.columns, results.values[0]);
  }
  private upsert(tag: DbTag) {
    const cols = Object.keys(dbTagSchema.properties);
    upsert(tag, this.tableName, cols, this.db);
  }
  private rowToTag(columns: string[], values: SqlValue[]): DbTag | undefined {
    const object = dbTagRowToNative(columns, values);
    if (isDbTag(object)) {
      return object;
    } else {
      return;
    }
  }
}
