import { Skeleton } from "@mui/material";
import React, { useState } from "react";
import { usePullFile } from "../../../hooks";

export function DetailImage({ id }: { id: string }) {
  const { pulled } = usePullFile(id);
  const [loaded, setLoaded] = useState(false);
  const skel = (
    <Skeleton animation="wave" variant="rectangular" width={300} height={300} />
  );
  if (!pulled) {
    return skel;
  }
  return (
    <>
      {!loaded && skel}
      {pulled && (
        <img
          src={`/files/${id}`}
          onLoad={() => {
            setLoaded(true);
            return;
          }}
        />
      )}
    </>
  );
}
