import { Alert, Box, LinearProgress } from "@mui/material";
import OpenAI from "openai";
import {
  ChatCompletion,
  ChatCompletionCreateParamsNonStreaming,
  ChatCompletionMessageParam,
} from "openai/resources/chat/completions";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { uuid } from "short-uuid";
import { ChatMessage } from "../../db-chat-messages";
import { ObjectListItem } from "../../db-queries";
import { ObjectDetails } from "../../fp-api";
import { isGptMessage } from "../../gpt-types";
import { useDbQueries, useFpApi, useObjectAsSetting } from "../../notes-hooks";
import { ChatInput } from "./ChatInput";
import { Messages } from "./Messages";

export function AgentView({ object }: { id: string; object: ObjectDetails }) {
  const { messages, addMessage, getResponse, error, loading, deleteMessage, runTool } =
    useChat(object);
  function handleSubmit(text: string) {
    const message: ChatMessage = { id: uuid(), objectId: object.id, gptMessage: {
      version: "v1",
      message: {
        content: text,
        role: "user",
      }
    }};
    addMessage(message);
    getResponse([...messages, message]);
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        overflowY: "hidden",
        minHeight: "0px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column-reverse",
          overflow: "auto",
          flexGrow: 1,
        }}
      >
        <Messages
          messages={messages.slice().reverse()}
          onDelete={deleteMessage}
        />
      </Box>
      {error && <Alert severity="error">{error}</Alert>}
      {loading && <LinearProgress />}
      <Box display="flex">
        <ChatInput onSubmit={handleSubmit} />
      </Box>
    </Box>
  );
}

function useToolRunner(obj: ObjectDetails) {
  const runTool = useCallback((id: string, name: string, args: string) => {
    return "Not implemented";
  }, []);
  return { runTool };
}

function useChat(obj: ObjectDetails) {
  const systemMessage = useSystemMessage(obj);
  const { getChatCompletion } = useChatCompletion(systemMessage);
  const { messages, addMessage, deleteMessage } = useMessages(obj.id);
  const toolRunner = useToolRunner(obj);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  useEffect(() => {
    const currentController = abortControllerRef.current;
    return () => {
      if (currentController) {
        currentController.abort();
      }
    };
  }, []);

  const getResponse = useCallback(
    async (messages: ChatMessage[]) => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();
      try {
        setError("");
        setLoading(true);
        const resp = await getChatCompletion(
          {
            model: "gpt-3.5-turbo",
            messages: messages.map(toGptMessage),
          },
          {
            signal: abortControllerRef.current.signal,
          }
        );
        const msg = toChatMessage(obj.id, resp);
        addMessage(msg);
      } catch (e: any) {
        if (e.name !== "AbortError") {
          setError(e.message);
        }
      } finally {
        setLoading(false);
      }
    },
    [addMessage, getChatCompletion]
  );

  const runTool = useCallback((id: string, name: string, args: string) => {
    const result = toolRunner.runTool(id, name, args);
    addMessage({
      id: uuid(),
      objectId: obj.id,
      gptMessage: {
        version: "v1",
        message: {
          content: result,
          role: "tool",
          tool_call_id: id,
        }
      }
    });
  }, []);

  return { messages, getResponse, addMessage, error, loading, deleteMessage, runTool };
}

function useSystemMessage(obj: ObjectDetails) {
  const queries = useDbQueries();
  const outgoing = queries.tagsOfObject(obj.id);
  const incoming = queries.objectsWithTag(obj.id, "");
  return makeSystemMessage(obj, incoming, outgoing);
}

function makeSystemMessage(
  obj: ObjectDetails,
  incoming: ObjectListItem[],
  outgoing: ObjectListItem[]
) {
  const incomingStr = incoming
    .map((x) => `- ${stringifyListItem(x)}`)
    .join("\n");
  const outgoingStr = outgoing
    .map((x) => `- ${stringifyListItem(x)}`)
    .join("\n");
  const { id, title, text } = obj;
  return [
    `You are an assistant that helps a user interact with a note in a note database.`,
    `The user is currently looking at this note:`,
    "```",
    JSON.stringify({ id, title, text }, null, 2),
    "```",
    "The note database forms a graph of notes connected by tags.",
    "This note has outgoing connections to the following notes:",
    outgoingStr,
    "This note has incoming connections from the following notes:",
    incomingStr,
    "",
    "Always render notes like this: [note title](/notes/note/note-id).",
  ].join("\n");
}

function stringifyListItem(item: ObjectListItem) {
  const id = item.id;
  const title = item.title.replace("\n", "  ");
  const text = trim(item.text, 250).replace("\n", "  ");
  return `${id}: ${title} - ${text}`;
}

function trim(str: string, len: number) {
  return str.length > len ? str.substring(0, len) + "..." : str;
}

function useMessages(_id: string) {
  const fpApi = useFpApi(true);
  const messages = fpApi.dbChatMessages.getMessagesForObjectId(_id);
  const addMessage = (message: ChatMessage) => {
    fpApi.dbChatMessages.addMessage(message);
  };
  const deleteMessage = (id: string) => {
    fpApi.dbChatMessages.deleteMessage(id);
  };
  return { messages, addMessage, deleteMessage };
}

function useChatCompletion(systemMessage = "") {
  const {
    value: apiKey,
    error: keyError,
    id: keyId,
  } = useObjectAsSetting("OpenAI Key");
  const openAi = useMemo(() => {
    return new OpenAI({
      dangerouslyAllowBrowser: true,
      apiKey,
    });
  }, [apiKey]);
  const getChatCompletion = useCallback(
    (
      req: ChatCompletionCreateParamsNonStreaming,
      opts?: { signal: AbortSignal }
    ) => {
      return openAi.chat.completions.create(
        {
          ...req,
          tools: [
            {
              type: "function",
              function: {
                name: "weather",
                description: "Get the weather",
                parameters: {
                  type: "object",
                  properties: {
                    location: {
                      type: "string",
                      description: "The location to get the weather for",
                    },
                  },
                }
              }
            }
          ],
          messages: [
            { role: "system", content: systemMessage },
            ...req.messages,
          ],
        },
        opts
      );
    },
    [openAi.chat.completions, systemMessage]
  );
  return { getChatCompletion, keyError, keyId };
}

function toGptMessage(chatMessage: ChatMessage): ChatCompletionMessageParam {
  return chatMessage.gptMessage.message;
}

function toChatMessage(objectId: string, resp: ChatCompletion): ChatMessage {
  if (!resp.choices[0]?.message) {
    throw new Error("No response from GPT");
  }
  const message = resp.choices[0].message;
  if (!isGptMessage(message)) {
    throw new Error("Invalid GPT message");
  }
  return {
    objectId,
    id: uuid(),
    gptMessage: {
      version: "v1",
      message,
    }
  };
}
