import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { ObjectListItem } from "./fp-api";
import { ListView } from "./ListView";
import { useDbQueries, useObjectNavigate } from "./notes-hooks";
import { Link } from "react-router-dom";

const pageSize = 100;
export function RandomNotesView() {
  const queries = useDbQueries();
  const navigate = useObjectNavigate();
  return (
    <Stack sx={{ marginTop: 2, display: "flex", flexGrow: 1 }}>
      <Box>
        <Button component={Link} to="/notes/random">
          Shuffle
        </Button>
      </Box>
      <Box sx={{ display: "flex", flexGrow: 1 }}>
        <ListView<ObjectListItem>
          placeholder="Search over random objects"
          getObjects={(input, page) =>
            queries.searchRandomObjects(input, pageSize, pageSize * page)
          }
          onSelect={(object) => navigate(object.id)}
          getPrimaryText={(object) => object.title}
          getSecondaryText={(object) => object.text}
          noHits={(query) => (
            <Container>
              <Typography variant="subtitle1">
                No objects matching &ldquo;{query}&rdquo;
              </Typography>
            </Container>
          )}
        />
      </Box>
    </Stack>
  );
}
