import { useLiveQuery } from "dexie-react-hooks";
import { useContext, useEffect, useMemo, useState } from "react";
import { throttleTime } from "rxjs";
import { CreateTableOp } from "../data/conflict-free-db/operations";
import { getController } from "../data/controller";
import { FileData, FileMetadata, requestPull } from "../data/file-state";
import { FileStats } from "../data/file-store";
import { GDriveFolder } from "../data/google-drive/google-folder-picker";
import { getLogController } from "../data/logging";
import { SourceType } from "../data/source-state";
import { SourceContext, TopBarContext } from "./Layout";
import { SchemaDef } from "./notes/schema";

export function useSyncQueue() {
  throw "removed";
}

export enum OnlineStatus {
  online = "online",
  offline = "offline",
}

export function useOnlineStatus() {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  useEffect(() => {
    const setOnline = () => setIsOnline(true);
    const setOffline = () => setIsOnline(false);
    window.addEventListener("offline", setOffline);
    window.addEventListener("online", setOnline);
    return () => {
      window.removeEventListener("offline", setOffline);
      window.removeEventListener("online", setOnline);
    };
  }, []);
  return isOnline;
}

export function useSourceList(includeDefault = true) {
  return useLiveQuery(
    () => getController().fs.listSources(includeDefault),
    [includeDefault]
  );
}

export function useSource(id: string) {
  return useLiveQuery(() => getController().fs.getSource(id), [id]);
}

export function useSourceByName(name: string) {
  return useLiveQuery(() => getController().fs.getSourceByName(name), [name]);
}

export function useFileList(sourceId: string) {
  const files = useLiveQuery(
    () => getController().fs.listFiles(sourceId),
    [sourceId]
  );
  return files;
}

export function useImageList(sourceId: string) {
  const files = useFileList(sourceId);
  const [images, setImages] = useState<string[]>([]);
  useEffect(() => {
    let subscribed = true;
    async function run() {
      if (!files) {
        return;
      }
      const images: string[] = [];

      for (const file of files) {
        const data = await getController().fs.getFileData(file);
        if (data?.metadata.contentType.startsWith("image/")) {
          images.push(file);
        }
      }
      if (subscribed) {
        setImages(images);
      }
    }
    run();
    return () => {
      subscribed = false;
    };
  }, [files]);
  return images;
}

export function useSyncState(id: string) {
  const state = useLiveQuery(() => getController().fs.getSyncState(id), [id]);
  if (!state) {
    return {
      needsPush: false,
      pushError: undefined,
      needsPull: false,
      needsCrawl: false,
    };
  }
  return state;
}

export function usePullFile(id: string | undefined, shouldPull = true) {
  const file = useFileData(id, shouldPull);
  const pulled = file?.data;
  return { pulled };
}

export function useFileData(id: string | undefined, shouldPull = true) {
  const sourceId = useSourceId();
  const data = useLiveQuery(async () => {
    const fs = getController().fs;
    const fileData = await fs.getFileData(id ?? "");
    if (!fileData && id && shouldPull) {
      await fs.files.apply(id, requestPull(sourceId));
    }
    return fileData;
  }, [id]);
  function setData(data: FileData) {
    if (!id) {
      return;
    }
    return getController().fs.setFileData(id, data);
  }
  function setMetadata(metadata: FileMetadata) {
    if (data) {
      setData({ ...data, metadata });
    }
  }
  function deleteFile() {
    if (data) {
      setMetadata({ ...data.metadata, isDeleted: true });
    }
  }
  function setContent(content: ArrayBuffer) {
    if (data) {
      setData({ ...data, content });
    }
  }
  return { data, setMetadata, setData, setContent, deleteFile };
}

export function useAddFile(sourceId: string) {
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState<string>();
  async function addFile(name: string, content: string) {
    if (isPending) {
      return;
    }
    setIsPending(true);
    try {
      getController().addTextFile(sourceId, name, content);
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
    } finally {
      setIsPending(false);
    }
  }
  return { error, isPending, addFile };
}

export function useAddSource() {
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState<string>();
  async function addSource(name: string, type: SourceType, config: unknown) {
    if (isPending) {
      return;
    }
    setIsPending(true);
    try {
      await getController().addSource(name, type, config);
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
    } finally {
      setIsPending(false);
    }
  }
  return { error, isPending, addSource };
}

export function useDeleteSource(id: string) {
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState<string>();
  async function deleteSource() {
    if (isPending) {
      return;
    }
    setIsPending(true);
    try {
      await getController().deleteSource(id);
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
    } finally {
      setIsPending(false);
    }
  }
  return { error, isPending, deleteSource };
}

export function useGDriveFolderPicker() {
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState<string>();
  const [folder, setFolder] = useState<GDriveFolder>();
  async function pick() {
    if (isPending) {
      return;
    }
    setIsPending(true);
    try {
      const controller = getController();
      const folder = await controller.pickGDriveFolder();
      setFolder(folder);
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
    } finally {
      setIsPending(false);
    }
  }
  const clear = () => setFolder(undefined);
  return { error, isPending, folder, pick, clear };
}

export function useLog() {
  const log = useLiveQuery(() => getLogController().getLog(), []);
  function clear() {
    getLogController().clearLog();
  }
  return { log, clear };
}

export function useFileStats() {
  const controller = getController();
  const [stats, setStats] = useState<FileStats>();
  useEffect(() => {
    async function update() {
      const stats = await controller.fs.fileStats();
      setStats(stats);
    }
    update();
    const sub = controller.fs
      .fileListObservable()
      .pipe(
        throttleTime(1000, undefined, {
          leading: true,
          trailing: true,
        })
      )
      .subscribe(update);
    return () => sub.unsubscribe();
  }, [controller]);
  return stats;
}

export function useDb() {
  const sourceId = useSourceId();
  const controller = getController();
  return controller.sqlDbs.getDb(sourceId);
}

export function useDbSubscription(subscribe = true) {
  const sourceId = useSourceId();
  const controller = getController();
  const initialDb = useMemo(
    () => controller.sqlDbs.getDb(sourceId),
    [sourceId, controller]
  );
  const [state, setState] = useState({ db: initialDb });
  useEffect(() => {
    const sub = state.db.allChangeEvents().subscribe(() => {
      if (subscribe) {
        setState({ db: state.db });
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, [state.db, subscribe]);
  return state.db;
}

export function useSourceId() {
  const sourceId = useContext(SourceContext);
  return sourceId ?? "";
}

export function useTopBar() {
  return useContext(TopBarContext);
}

export function useSqlSchema(schemaDefs: SchemaDef[]) {
  const sourceId = useSourceId();
  const controller = getController();
  const db = controller.sqlDbs.getDb(sourceId);
  const [created, setCreated] = useState(false);
  useEffect(() => {
    for (const def of schemaDefs) {
      if (def.type === "table") {
        const createTable = new CreateTableOp(def.table, def.columns);
        db.applyLocalOp(createTable);
      }
    }
    setCreated(true);
  }, [db, schemaDefs]);
  return created;
}

export function useFileAdder() {
  const sourceId = useSourceId();
  const controller = getController();
  async function addFile(file: File) {
    const content = await file.arrayBuffer();
    const id = await controller.addFile(
      sourceId,
      file.name,
      content,
      file.type
    );
    return id;
  }
  return { addFile };
}

export function useDebounce<T>(value: T, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}
