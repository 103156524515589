import { Workbox } from "workbox-window";

export async function initializeServiceWorker() {
  if (!("serviceWorker" in navigator)) {
    throw Error("serviceWorker not enabled");
  }
  const reg = await navigator.serviceWorker.getRegistration();
  // refresh the page if we're not running the latest service worker
  if (reg?.active && !navigator.serviceWorker.controller) {
    window.location.reload();
  }
  const wb = new Workbox("/service-worker.js");
  await wb.register();
}
