import React from "react";
import { InlineEdit } from "../../InlineEdit";

export function QueryParamForm({
  name,
  value,
  onChange,
}: {
  name: string;
  value: string;
  onChange: (value: string) => void;
}) {
  return <InlineEdit label={name} defaultValue={value} onSave={onChange} />;
}
