import {
  Change,
  deserializeChange,
  serializeChange,
} from "./conflict-free-db/core";
import {
  decodeString,
  encodeString,
  FileMetadata,
  FileState,
  localAdd,
  localUpdate,
  md5,
} from "./file-state";
import { FileStore } from "./file-store";
import { GDriveFolder } from "./google-drive/google-folder-picker";
import { IdGeneratorByType } from "./id-generator";
import { setDbPersistenceFile, SourceState, SourceType } from "./source-state";
import { SourceStore } from "./source-store";

export const sqlFileMimeType = "application/json";
export const sqlFileExtension = ".db.json";
export function isSqlFile<T extends FileState>(state: T) {
  if (!state.data?.metadata || state.data.metadata.isDeleted) {
    return false;
  }
  const { contentType, name } = state.data.metadata;
  return (
    contentType === sqlFileMimeType &&
    name.toLocaleLowerCase().endsWith(sqlFileExtension)
  );
}

export function parseEventChangeFile(data: ArrayBuffer): Change[] {
  const json = decodeString(data);
  const results: Change[] = [];
  for (const record of JSON.parse(json)) {
    const change = deserializeChange(record);
    results.push(change);
  }
  return results;
}

function getParent(source: SourceState): string | undefined {
  if (source.type === SourceType.gdrive) {
    const folder = source.config as GDriveFolder;
    return folder.id;
  }
  return undefined;
}

export class DbPersistenceFile {
  constructor(private fileId: string, private fileStore: FileStore) {}
  async read(): Promise<Change[]> {
    const file = await this.fileStore.get(this.fileId);
    if (!file) {
      throw new Error("file not found");
    }
    if (!isSqlFile(file)) {
      throw new Error("not a db file");
    }
    if (file.data?.content) {
      return parseEventChangeFile(file.data.content);
    } else {
      return [];
    }
  }
  async append(changes: Change[]): Promise<void> {
    const log = await this.read();
    const newLog = log.concat(changes);
    await this.write(newLog);
  }
  async sizeBytes(): Promise<number> {
    const file = await this.fileStore.get(this.fileId);
    if (!file || !file.data) {
      throw new Error("file not found");
    }
    return file.data.content.byteLength;
  }
  private async write(changes: Change[]): Promise<void> {
    const raw = changes.map(serializeChange);
    const content = encodeString(JSON.stringify(raw));
    const data = {
      content,
      metadata: await this.makeMetadata(content),
    };
    await this.fileStore.apply(this.fileId, localUpdate(data));
  }
  private async makeMetadata(content: Uint8Array): Promise<FileMetadata> {
    const file = await this.fileStore.get(this.fileId);
    if (!file?.data) {
      throw new Error(`file does not exist: ${this.fileId}`);
    }
    const metadata = file.data.metadata;
    const now = new Date().getTime();
    return {
      ...metadata,
      isDeleted: false,
      createdAt: now,
      modifiedAt: now,
      md5Checksum: md5(content),
    };
  }
}

export interface DbPersistenceManagerOpts {
  sourceId: string;
  sourceStore: SourceStore;
  fileStore: FileStore;
  idGenerator: IdGeneratorByType;
  maxFileSizeBytes: number;
}
export class DbPersistenceManager {
  private sourceId: string;
  private sourceStore: SourceStore;
  private fileStore: FileStore;
  private idGenerator: IdGeneratorByType;
  private maxFileSizeBytes: number;
  constructor(opts: DbPersistenceManagerOpts) {
    this.sourceId = opts.sourceId;
    this.sourceStore = opts.sourceStore;
    this.fileStore = opts.fileStore;
    this.idGenerator = opts.idGenerator;
    this.maxFileSizeBytes = opts.maxFileSizeBytes;
  }
  async append(changes: Change[]): Promise<void> {
    const file = await this.getFile();
    await file.append(changes);
  }
  private async getFile(): Promise<DbPersistenceFile> {
    const existing = await this.tryGetFileFromStore();
    if (existing) {
      return existing;
    } else {
      return this.createFile();
    }
  }
  private async tryGetFileFromStore(): Promise<DbPersistenceFile | undefined> {
    const source = await this.getSource();
    if (!source.dbFile) {
      return;
    }
    if (!(await this.validDocStoreFile(source.dbFile))) {
      return;
    }
    const file = new DbPersistenceFile(source.dbFile, this.fileStore);
    const size = await file.sizeBytes();
    if (size <= this.maxFileSizeBytes) {
      return file;
    }
    console.log("too big!");
  }
  private async createFile(): Promise<DbPersistenceFile> {
    const source = await this.getSource();
    const generator = this.idGenerator[source.type];
    const id = await generator();
    const content = encodeString(JSON.stringify([]));
    const add = localAdd(this.sourceId, {
      content,
      metadata: await this.makeMetadata(content),
    });
    await this.fileStore.apply(id, add);
    await this.sourceStore.apply(this.sourceId, setDbPersistenceFile(id));
    return new DbPersistenceFile(id, this.fileStore);
  }
  private async getSource(): Promise<SourceState> {
    const source = await this.sourceStore.get(this.sourceId);
    if (!source) {
      throw new Error("cannot find source");
    }
    return source;
  }
  private async makeMetadata(content: Uint8Array): Promise<FileMetadata> {
    const source = await this.getSource();
    const now = new Date().getTime();
    return {
      name: `FolderPlace${sqlFileExtension}`,
      contentType: sqlFileMimeType,
      isDeleted: false,
      parent: getParent(source),
      createdAt: now,
      modifiedAt: now,
      md5Checksum: md5(content),
    };
  }
  private async validDocStoreFile(id?: string) {
    if (!id) {
      return false;
    }
    const file = await this.fileStore.get(id);
    return file && isSqlFile(file);
  }
}

const maxFileSizeBytes = 100 * 1000;
export function dbPersistence(
  sourceId: string,
  sourceStore: SourceStore,
  fileStore: FileStore,
  idGenerator: IdGeneratorByType
) {
  const manager = new DbPersistenceManager({
    sourceId,
    sourceStore,
    fileStore,
    idGenerator,
    maxFileSizeBytes,
  });
  return async function append(change: Change) {
    return manager.append([change]);
  };
}
