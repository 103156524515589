import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { ObjectListItem } from "./fp-api";
import { ListView } from "./ListView";
import { useDbQueries, useObjectNavigate } from "./notes-hooks";

const pageSize = 100;
export function StarredNotesView() {
  const queries = useDbQueries();
  const navigate = useObjectNavigate();
  return (
    <Box sx={{ marginTop: 2, display: "flex", flexGrow: 1 }}>
      <ListView<ObjectListItem>
        placeholder="Search over starred objects"
        getObjects={(input, page) =>
          queries.searchStarredObjects(input, pageSize, pageSize * page)
        }
        onSelect={(object) => navigate(object.id)}
        getPrimaryText={(object) => object.title}
        getSecondaryText={(object) => object.text}
        noHits={(query) => (
          <Container>
            <Typography variant="subtitle1">
              No trashed objects matching &ldquo;{query}&rdquo;
            </Typography>
          </Container>
        )}
      />
    </Box>
  );
}
