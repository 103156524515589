import { Container } from "@mui/material";
import React from "react";
import { defaultSourceId } from "../data/source-store";
import { DeleteButton } from "./DeleteButton";
import { useDeleteSource, useSource, useSourceList } from "./hooks";
import { LinkToSource } from "./LinkToSource";
import { SourceListControl } from "./SourceListControl";

function SourceListItem({ id }: { id: string }) {
  const source = useSource(id);
  const { deleteSource } = useDeleteSource(id);
  if (!source) {
    return <></>;
  }
  const deleteButton = <DeleteButton onClick={deleteSource} />;
  return (
    <>
      {id === defaultSourceId ? null : deleteButton}
      <LinkToSource name={source.name}>{source.name}</LinkToSource>(
      {source.type})
    </>
  );
}

function SourceList() {
  const sources = useSourceList();
  if (!sources) {
    return <></>;
  }
  const items = sources
    .filter((x) => x !== defaultSourceId)
    .map((id) => (
      <li key={id}>
        <SourceListItem id={id} />
      </li>
    ));
  return <ul>{items}</ul>;
}

export function SourceListView() {
  return (
    <Container>
      <h2>Sources</h2>
      <SourceList />
      <hr />
      <h2>Add Source</h2>
      <SourceListControl />
    </Container>
  );
}
