import React, { useEffect, useState } from "react";
import { openDB } from "idb";
import { SourceSelector } from "./SourceSelector";
import { defaultSourceId } from "../data/source-store";
import { useSourceList } from "./hooks";
import { useNavigate } from "react-router-dom";
import { getController } from "../data/controller";
import { Box } from "@mui/material";

const dbName = "share_target";
const storeName = "shares";

async function clearShareFiles(): Promise<void> {
  const db = await openDB(dbName);
  db.clear(storeName);
}

async function getShareFiles(): Promise<File[]> {
  const db = await openDB(dbName);
  const records = await db
    .transaction(storeName)
    .objectStore(storeName)
    .getAll();
  const files: File[] = [];
  for (const record of records) {
    if (!record.files) {
      continue;
    }
    for (const { name, data, type } of record.files) {
      if (
        typeof name === "string" &&
        typeof type === "string" &&
        data instanceof ArrayBuffer
      ) {
        const file = new File([data], name, { type: type });
        files.push(file);
      }
    }
  }
  return files;
}

function useShareFiles() {
  const [files, setFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    async function load() {
      setLoading(true);
      try {
        const files = await getShareFiles();
        setFiles(files);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }
    load();
  }, []);
  return { files, loading, error };
}

function ShareFiles({ files }: { files: File[] }) {
  const sources = useSourceList(false);
  const [sourceId, setSourceId] = useState(defaultSourceId);
  const [pending, setPending] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    return () => {
      clearShareFiles();
    };
  }, []);
  async function add() {
    const controller = getController();
    const api = controller.fpApis.get(sourceId);
    setPending(true);
    for (const file of files) {
      await api.addFile(file);
    }
    await clearShareFiles();
    navigate("/notes");
  }
  if (!sources?.length) {
    return <>You need to create a folder place before you can share files</>;
  }
  return (
    <>
      <SourceSelector onChange={setSourceId} />
      <ul>
        {files.map((file, i) => (
          <li key={i}>{file.name}</li>
        ))}
      </ul>
      <button onClick={add} disabled={pending}>
        {pending ? "Importing..." : "Import"}
      </button>
    </>
  );
}

export function ShareTarget() {
  const { files, loading, error } = useShareFiles();
  let content;
  if (loading) {
    content = <>Loading...</>;
  } else if (error) {
    content = <>Error loading share content: {error}</>;
  } else {
    content = <ShareFiles files={files} />;
  }
  return (
    <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
      <h2>Import items</h2>
      {content}
    </Box>
  );
}
