import stableStringify from "json-stable-stringify";

export class LwwRegister<T> {
  constructor(private value: T, private timestamp: number) {}
  getValue() {
    return this.value;
  }
  getTimestamp() {
    return this.timestamp;
  }
  trySet(value: T, timestamp: number): boolean {
    if (this.timestamp > timestamp) {
      return false;
    }
    if (this.timestamp < timestamp || compareValues(this.value, value) < 0) {
      this.value = value;
      this.timestamp = timestamp;
      return true;
    }
    return false;
  }
}

function compareValues(a: any, b: any) {
  if (a === b) {
    return 0;
  }
  const ta = typeof a;
  const tb = typeof b;
  if (ta !== tb) {
    return ta.localeCompare(tb);
  }
  if (ta === "string") {
    return a.localeCompare(b);
  }
  if (ta === "number") {
    return a < b ? -1 : 1;
  }
  if (ta === "boolean") {
    return !a ? -1 : 1;
  }
  const sa = stableStringify(a) || "";
  const sb = stableStringify(b) || "";
  return sa.localeCompare(sb);
}
