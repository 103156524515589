import { Info } from "@mui/icons-material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import {
  Alert,
  CircularProgress,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { QueryExecResult } from "sql.js";
import { useDbSubscription } from "./hooks";
import { DbQueries } from "./notes/db-queries";
import { ObjectDetails } from "./notes/fp-api";
import { GptSql } from "./notes/gpt";
import { useDbQueries, useObjectAsSetting } from "./notes/notes-hooks";
import { SqlQueryForm } from "./notes/views/sql/SqlQueryForm";

const maxRows = 100;
function SqlResults({
  query,
  args,
}: {
  query: string | undefined;
  args: Record<string, string> | undefined;
}) {
  const db = useDbSubscription();
  const [results, setResults] = useState<QueryExecResult | undefined>(
    undefined
  );
  const [error, setError] = useState("");
  const [time, setTime] = useState(0);
  useEffect(() => {
    try {
      if (query) {
        const now = performance.now();
        const [results] = db.queryShared(query, args);
        setTime(performance.now() - now);
        setResults(results);
        setError("");
      }
    } catch (e: any) {
      setError(e.message);
      setTime(0);
    }
  }, [db, query, args]);
  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }
  if (query === undefined || !results) {
    return null;
  }
  return (
    <>
      {results.values.length > maxRows && (
        <Alert severity="warning" sx={{ marginBottom: 2 }}>
          Truncating to first {maxRows} rows
        </Alert>
      )}
      {time && (
        <Alert severity="info" sx={{ marginBottom: 2 }}>
          {results.values.length} record{results.values.length === 1 ? "" : "s"}{" "}
          ({time.toFixed(0)} ms)
        </Alert>
      )}
      <TableContainer component={Paper} elevation={3}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {results.columns.map((name, index) => (
                <TableCell key={index}>{name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {results.values.slice(0, maxRows).map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {row.map((value, valueIndex) => (
                  <TableCell key={valueIndex}>{value}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export function SqlView({ object }: { object: ObjectDetails }) {
  const [input, setInput] = useState("");
  const [query, setQuery] = useState<undefined | string>(undefined);
  const [args, setArgs] = useState<Record<string, string>>({});
  const dbQueries = useDbQueries();
  return (
    <Stack padding={1} spacing={1}>
      <SqlQueryForm
        onSubmit={(v) => {
          if (v) {
            setInput(v);
            setQuery(v);
          }
        }}
      />
      <GptQueryGenerator
        onGenerate={setInput}
        object={object}
        dbQueries={dbQueries}
      />
      <Stack direction="row" spacing={1}>
        <IconButton
          onClick={() => {
            const query = `select * from sqlite_schema;`;
            setInput(query);
            setQuery(query);
          }}
        >
          <Info />
        </IconButton>
        <OutlinedInput
          size="small"
          fullWidth
          placeholder="SELECT * FROM ..."
          multiline
          value={input}
          onChange={(e) => setInput(e.target.value)}
          inputProps={{
            sx: {
              fontFamily: "monospace",
            },
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton size="small" onClick={() => setQuery(input)}>
                <PlayArrowIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </Stack>
      <SqlResults query={query} args={args} />
    </Stack>
  );
}

function GptQueryGenerator({
  object,
  dbQueries,
  onGenerate,
}: {
  object: ObjectDetails;
  dbQueries: DbQueries;
  onGenerate: (query: string) => void;
}) {
  const [pending, setPending] = useState(false);
  const [input, setInput] = useState("");
  const [error, setError] = useState("");
  const {
    value: openAiKey,
    error: keyError,
    id: keyId,
  } = useObjectAsSetting("OpenAI Key");
  const {
    value: prompt,
    error: promptError,
    id: promptId,
  } = useObjectAsSetting("SQL Prompt", true);
  async function handleSubmit() {
    if (!openAiKey) {
      return;
    }
    setPending(true);
    try {
      const gpt = new GptSql({
        apiKey: openAiKey,
        dbQueries,
        object,
        prompt: prompt ?? "",
      });
      const query = await gpt.getSql(input);
      query && onGenerate(query);
    } catch (e: any) {
      setError(e.message);
    } finally {
      setPending(false);
    }
  }
  const disabled = !openAiKey || pending;
  return (
    <Stack spacing={1}>
      {!openAiKey && (
        <Alert severity="warning">No OpenAI Key: {keyError}</Alert>
      )}
      {promptError && <Alert severity="warning">{promptError}</Alert>}
      {openAiKey && (
        <Alert severity="info">
          Using <Link to={`/notes/note/${keyId}`}>this note</Link> for OpenAI
          key
        </Alert>
      )}
      {promptId && (
        <Alert severity="info">
          Including <Link to={`/notes/note/${promptId}`}>this note</Link> in the
          prompt
        </Alert>
      )}
      {error && <Alert severity="error">{error}</Alert>}
      <OutlinedInput
        disabled={disabled}
        size="small"
        fullWidth
        placeholder="What query do you want?"
        multiline
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onSubmit={handleSubmit}
        endAdornment={
          <InputAdornment position="end">
            {pending && <CircularProgress size="20px" />}
            <IconButton disabled={disabled} size="small" onClick={handleSubmit}>
              <PlayArrowIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </Stack>
  );
}
