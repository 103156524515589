import React from "react";
import { ChatMessage } from "../../db-chat-messages";
import { Message } from "./Message";

export function Messages({
  messages,
  onDelete,
}: {
  messages: ChatMessage[];
  onDelete: (id: string) => void;
}) {
  return (
    <>
      {messages.map((message) => (
        <Message
          key={message.id}
          data={message}
          onDelete={() => onDelete(message.id)}
        />
      ))}
    </>
  );
}
