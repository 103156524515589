import { YouTube } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useState } from "react";

function shuffleArray<T>(array: T[]) {
  const result = [...array];
  for (let i = result.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [result[i], result[j]] = [result[j], result[i]];
  }
  return result;
}

export function PlaylistAction({ ids }: { ids: string[] }) {
  const [shuffled, setShuffled] = useState([...ids]);
  const shuffle = () => setShuffled(shuffleArray(shuffled));
  const idList = shuffled.join(",");
  const url = `https://www.youtube.com/watch_videos?video_ids=${idList}`;
  return (
    <Button
      variant="outlined"
      endIcon={<YouTube />}
      size="small"
      component="a"
      href={url}
      target="_blank"
      onClick={shuffle}
    >
      Playlist
    </Button>
  );
}
