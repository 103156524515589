import { Alert } from "@mui/material";
import React from "react";
import { FpApi, ObjectDetails, ViewRef } from "../fp-api";
import { ExploreView } from "../views/ExploreView";
import { GalleryView } from "../views/GalleryView";
import { ImageListView } from "../views/ImageListView";
import { NetworkView } from "../views/NetworkView";
import { ObjectCanvasView } from "../views/ObjectCanvasView";
import { ObjectContentView } from "../views/ObjectContentView";
import { RelatedTagsView } from "../views/RelatedTagsView";
import { SettingsView } from "../views/SettingsView";
import { TagsView } from "../views/TagsView";
import { AgentView } from "../views/agent/AgentView";
import { ObjectSqlView } from "../views/sql/ObjectSqlView";

export function ViewRenderer({
  object,
  api,
  view,
}: {
  object: ObjectDetails;
  api: FpApi;
  view: ViewRef;
}) {
  if (view.type !== "builtin") {
    return <Alert severity="error">Unknown view type: {view.type}</Alert>;
  }
  switch (view.builtinType) {
    case "content":
      return <ObjectContentView object={object} api={api} />;
    case "tags":
      return <TagsView id={object.id} />;
    case "related":
      return <RelatedTagsView id={object.id} />;
    case "gallery":
      return <GalleryView id={object.id} />;
    case "explore":
      return <ExploreView id={object.id} object={object} />;
    case "network":
      return <NetworkView id={object.id} object={object} />;
    case "sql":
      return <ObjectSqlView object={object} />;
    case "canvas":
      return <ObjectCanvasView object={object} />;
    case "settings":
      return <SettingsView object={object} api={api} />;
    case "imageList":
      return <ImageListView id={object.id} />;
    case "agent":
      return <AgentView id={object.id} object={object} />;
  }
}
