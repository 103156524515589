import { Parser } from "node-sql-parser";
import { ObjectListItem } from "./db-queries";

export interface SavedQuery {
  readonly id: string;
  readonly title: string;
  readonly text: string;
  readonly parsed: ParsedQuery;
}

export function createSavedQuery(obj: ObjectListItem): SavedQuery {
  const parsed = parseQuery(obj.text);
  return {
    id: obj.id,
    title: obj.title,
    text: obj.text,
    parsed,
  };
}

export type ParsedQuery = ParseSuccess | ParseFailure;

export interface ParseSuccess {
  readonly valid: true;
  readonly parameters: NamedParameter[];
}

export interface ParseFailure {
  readonly valid: false;
  readonly error: string;
}

export interface NamedParameter {
  readonly name: string;
}

const parser = new Parser();
export function parseQuery(query: string): ParsedQuery {
  try {
    const ast = parser.astify(query, { database: "sqlite" });
    const parameters = getParameters(ast);
    const distinctParameters = distinct(parameters, (p) => p.name);
    return {
      valid: true,
      parameters: distinctParameters,
    };
  } catch (e: any) {
    if (e.name === "SyntaxError") {
      return {
        valid: false,
        error: "Could not parse",
      };
    }
    return {
      valid: false,
      error: e.message,
    };
  }
}

// distinct by key
function distinct<T>(arr: T[], key: (item: T) => string): T[] {
  const seen = new Set<string>();
  return arr.filter((item) => {
    const k = key(item);
    if (seen.has(k)) {
      return false;
    }
    seen.add(k);
    return true;
  });
}

const depthLimit = 100;
function getParameters(obj: any, depth = 0): NamedParameter[] {
  if (depth > depthLimit) {
    throw new Error("Over depth limit");
  }
  if (!obj) {
    return [];
  }
  const param = asParameter(obj);
  if (param) {
    return [param];
  }
  if (Array.isArray(obj)) {
    return obj.flatMap((item) => getParameters(item, depth + 1));
  }
  if (typeof obj !== "object") {
    return [];
  }
  return Object.values(obj).flatMap(getParameters);
}

function asParameter(obj: any): NamedParameter | undefined {
  if (!obj) {
    return;
  }
  if (typeof obj !== "object") {
    return;
  }
  const keys = Object.keys(obj);
  if (keys.length !== 2) {
    return;
  }
  if (obj.type !== "param") {
    return;
  }
  if (typeof obj.value !== "string") {
    return;
  }
  return {
    name: obj.value,
  };
}
