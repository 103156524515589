import React from "react";
import { SourceAddForm } from "./SourceAddForm";

export function SourceListControl() {
  return (
    <>
      <SourceAddForm />
    </>
  );
}
