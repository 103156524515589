import { Box, Link } from "@mui/material";
import React, { PropsWithChildren, ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import { CellImage } from "../CellImage";
import { ObjectListItem } from "../db-queries";
import { useAddNote, useFpApi, useObjectNavigate } from "../notes-hooks";

export type QuickHitItem =
  | {
      type: "object";
      object: ObjectListItem;
    }
  | {
      type: "create-note";
      note: CreateNote;
    };

interface CreateNote {
  actionTitle: string;
  actionPreviewText?: string;
  noteTitle: string;
  content: string;
}

export function QuickHits({
  items,
  onNavigate,
}: {
  items: QuickHitItem[];
  onNavigate: () => void;
}) {
  if (items.length === 0) {
    return null;
  }
  return (
    <QuickHitContainer>
      {items.map((item, i) => (
        <QuickHitBox key={i}>
          <QuickHitContent item={item} onNavigate={onNavigate} />
        </QuickHitBox>
      ))}
    </QuickHitContainer>
  );
}

function QuickHitBox({ children }: PropsWithChildren) {
  return (
    <Box width={75} height={90} flex="0 0 auto">
      {children}
    </Box>
  );
}

function QuickHitContainer({ children }: PropsWithChildren) {
  return (
    <Box
      display="flex"
      flexWrap="nowrap"
      paddingBottom={1}
      paddingTop={2}
      paddingLeft={1}
      gap={1}
      sx={{ overflowX: "auto", overflowY: "hidden" }}
    >
      {children}
    </Box>
  );
}

function QuickHitContentLayout({
  icon,
  title,
}: {
  icon: ReactNode;
  title: ReactNode;
}) {
  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box flexGrow={1} display="flex" justifyContent="center" height={0}>
        {icon}
      </Box>
      <Box display="flex" p="3px" paddingBottom="5px" justifyContent="center">
        {title}
      </Box>
    </Box>
  );
}

function QuickHitContent({
  item,
  onNavigate,
}: {
  item: QuickHitItem;
  onNavigate: () => void;
}) {
  switch (item.type) {
    case "object":
      return (
        <QuickHitObjectContent object={item.object} onNavigate={onNavigate} />
      );
    case "create-note":
      return (
        <QuickHitCreateNoteContent note={item.note} onNavigate={onNavigate} />
      );
  }
}

function QuickHitCreateNoteContent({
  note,
  onNavigate,
}: {
  note: CreateNote;
  onNavigate: () => void;
}) {
  const addNote = useAddNote();
  const navigate = useObjectNavigate();
  const handleClick = () => {
    const id = addNote(note.noteTitle, note.content);
    navigate(id);
    onNavigate();
  };
  const previewText = note.actionPreviewText ?? note.noteTitle;
  return (
    <QuickHitContentLayout
      icon={
        <Link
          underline="none"
          variant="caption"
          color="inherit"
          onClick={handleClick}
        >
          <Box
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: "x-small",
              bgcolor: "lightyellow",
              lineHeight: "normal",
              wordBreak: "break-all",
              cursor: "pointer",
            }}
            width="60px"
            height="60px"
          >
            {previewText}
          </Box>
        </Link>
      }
      title={
        <Link
          underline="none"
          variant="caption"
          color="inherit"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            cursor: "pointer",
          }}
          onClick={handleClick}
        >
          {note.actionTitle}
        </Link>
      }
    />
  );
}

function QuickHitObjectContent({
  object,
  onNavigate,
}: {
  object: ObjectListItem;
  onNavigate: () => void;
}) {
  const linkUrl = `/notes/note/${object.id}`;
  return (
    <QuickHitContentLayout
      icon={
        <Link component={RouterLink} to={linkUrl} onClick={onNavigate}>
          <QuickHitAvatar object={object} />
        </Link>
      }
      title={
        <Link
          component={RouterLink}
          underline="none"
          to={linkUrl}
          variant="caption"
          color="inherit"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          onClick={onNavigate}
        >
          {object.title}
        </Link>
      }
    />
  );
}

function QuickHitAvatar({ object }: { object: ObjectListItem }) {
  const api = useFpApi(false);
  const details = api.objectDetails(object.id);
  const size = 60;
  if (details?.mainImage?.type === "youTube") {
    const url = `http://i3.ytimg.com/vi/${details.mainImage.image.youTubeId}/hqdefault.jpg`;
    return <img src={url} width={size} height={size} />;
  }
  const image = details?.mainImage?.image.imageId;
  return (
    <CellImage
      thumbnailSize={size}
      id={image ?? ""}
      width={size}
      height={size}
    />
  );
}
