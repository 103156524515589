import { useTheme } from "@mui/material";
import React, { useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { InlineEdit } from "../../InlineEdit";
import { FpApi, ObjectDetails } from "../../fp-api";

export function ObjectText({
  object,
  api,
}: {
  object: ObjectDetails;
  api: FpApi;
}) {
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement>(null);
  useHotkeys(
    "e",
    (e) => {
      e.preventDefault();
      setTimeout(() => inputRef.current?.focus(), 0);
    },
    [inputRef]
  );
  return (
    <InlineEdit
      inputRef={inputRef}
      defaultValue={object.text}
      onSave={(value) => api.setText(object.id, value)}
      fontSize={theme.typography.body1}
      placeholder="Add some text"
    />
  );
}
