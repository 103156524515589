import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ObjectListItem } from "./fp-api";
import { ListView } from "./ListView";
import { useDbQueries } from "./notes-hooks";

const pageCount = 100;
export function TrashedNotesView() {
  const queries = useDbQueries();
  const navigate = useNavigate();
  return (
    <Box sx={{ marginTop: 2, display: "flex", flexGrow: 1 }}>
      <ListView<ObjectListItem>
        placeholder="Search over trashed objects"
        getObjects={(input, page) =>
          queries.searchTrashedObjects(input, pageCount, pageCount * page)
        }
        onSelect={(object) => navigate(`/notes/note/${object.id}`)}
        getPrimaryText={(object) => object.title}
        getSecondaryText={(object) => object.text}
        noHits={(query) => (
          <Container>
            <Typography variant="subtitle1">
              No trashed objects matching &ldquo;{query}&rdquo;
            </Typography>
          </Container>
        )}
      />
    </Box>
  );
}
