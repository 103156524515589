import React from "react";
import { SourceType } from "../data/source-state";
import { useAddSource, useGDriveFolderPicker } from "./hooks";

export function SourceAddForm() {
  const type = SourceType.gdrive;
  const { error, isPending, addSource } = useAddSource();
  const { folder, pick, clear } = useGDriveFolderPicker();
  const errors = [];
  if (error) {
    errors.push(error);
  }
  if (!folder) {
    errors.push("Folder missing");
  }
  const valid = errors.length === 0;
  async function add() {
    if (!valid) {
      return;
    }
    await addSource(folder?.name ?? "New File", type, folder);
    clear();
  }
  const errorList = errors.map((e, i) => <div key={i}>{e}</div>);
  return (
    <>
      Folder: {folder ? folder.name : null}
      <button onClick={pick}>Pick Folder</button>
      <button disabled={!valid || isPending} onClick={add}>
        Add
      </button>
      {!valid ? errorList : null}
    </>
  );
}
