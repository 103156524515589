import { LwwRegister } from "./lww-register";

export class LwwMap<T> {
  private regs: Record<string, LwwRegister<T>> = {};
  private maxTimestamp = 0;
  constructor(private getDefault: () => T, data?: Record<string, [T, number]>) {
    if (data) {
      for (const [name, [value, timestamp]] of Object.entries(data)) {
        this.regs[name] = new LwwRegister(value, timestamp);
        this.updateMaxTimestamp(timestamp);
      }
    }
  }
  keys(): string[] {
    return Object.keys(this.regs);
  }
  entries(): [string, T][] {
    return Object.keys(this.regs).map((k) => [k, this.get(k)]);
  }
  trySet(name: string, value: T, timestamp: number): boolean {
    this.updateMaxTimestamp(timestamp);
    if (!this.regs[name]) {
      this.regs[name] = new LwwRegister(value, timestamp);
      return true;
    }
    return this.regs[name].trySet(value, timestamp);
  }
  get(name: string): T {
    return this.regs[name]?.getValue() ?? this.getDefault();
  }
  getMaxTimestamp() {
    return this.maxTimestamp;
  }
  dump(): Record<string, [T, number]> {
    const results: Record<string, [T, number]> = {};
    for (const [key, reg] of Object.entries(this.regs)) {
      results[key] = [reg.getValue(), reg.getTimestamp()];
    }
    return results;
  }
  private updateMaxTimestamp(timestamp: number) {
    this.maxTimestamp = Math.max(this.maxTimestamp, timestamp);
  }
}
