import { FromSchema } from "json-schema-to-ts";
import { AppSqlApi } from "../../data/app-sql-api";
import { defaultIdName } from "../../data/conflict-free-db/core";
import { DeleteRowsOp } from "../../data/conflict-free-db/operations";
import { createTableOp, makeValidator, rowToNative, upsert } from "./schema";

export const dbPropsSchema = {
  title: "DbProp",
  type: "object",
  properties: {
    [defaultIdName]: {
      type: "string",
    },
    objectId: {
      type: "string",
    },
    name: {
      type: "string",
    },
    value: {
      type: "string",
    },
  },
  required: [defaultIdName],
  additionalProperties: false,
} as const;
export type DbProp = FromSchema<typeof dbPropsSchema>;

const dbPropRowToNative = rowToNative(dbPropsSchema);
const isDbProp = makeValidator<DbProp>(dbPropsSchema);
export function initializeProps(tableName: string, db: AppSqlApi) {
  const createTable = createTableOp(tableName, dbPropsSchema);
  db.applyLocalOp(createTable);
  db.queryShared(
    `CREATE INDEX IF NOT EXISTS ${tableName}_objectId ON ${tableName}(objectId)`
  );
  db.queryShared(
    `CREATE INDEX IF NOT EXISTS ${tableName}_name ON ${tableName}(name)`
  );
}

export class DbProps {
  constructor(private db: AppSqlApi, readonly tableName = "props") {}
  initialize() {
    initializeProps(this.tableName, this.db);
  }

  get(objectId: string) {
    const [results] = this.db.queryShared(
      `SELECT * FROM ${this.tableName} WHERE objectId = ?`,
      [objectId]
    );
    if (!results || results.values.length === 0) {
      return [];
    }
    const rs: DbProp[] = [];
    const columns = results.columns;
    for (const row of results.values) {
      const val = dbPropRowToNative(columns, row);
      if (isDbProp(val)) {
        rs.push(val);
      }
    }
    return rs;
  }

  set(objectId: string, name: string, value: string) {
    const id = `${objectId}:${name}`;
    const row: DbProp = {
      [defaultIdName]: id,
      objectId,
      name,
      value,
    };
    this.upsert(row);
  }

  delete(objectId: string, name: string) {
    const id = `${objectId}:${name}`;
    const op = new DeleteRowsOp(this.tableName, [id]);
    this.db.applyLocalOp(op);
  }

  private upsert(row: DbProp) {
    const cols = Object.keys(dbPropsSchema.properties);
    upsert(row, this.tableName, cols, this.db);
  }
}
