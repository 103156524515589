import { GDriveIdDb } from "../app-db";
import { fetchIds, GetAuthToken } from "./google-drive-api";

export function getGdriveIdFromStore(db: GDriveIdDb) {
  return db.transaction("readwrite", db.gdriveId, async () => {
    const record = await db.gdriveId.limit(1).first();
    if (!record) {
      throw new Error("ids exhausted");
    }
    const { id } = record;
    await db.gdriveId.delete(id);
    return id;
  });
}

function idCount(db: GDriveIdDb) {
  return db.gdriveId.count();
}

async function isExhausted(db: GDriveIdDb, minCount: number) {
  const count = await idCount(db);
  return count < minCount;
}

async function refresh(db: GDriveIdDb, getToken: GetAuthToken) {
  const ids = await fetchIds(getToken);
  const records = ids.map((id) => ({ id }));
  await db.gdriveId.bulkAdd(records);
}

export function gdriveIdGenerator(
  db: GDriveIdDb,
  getToken: () => Promise<string>,
  minCount = 10
) {
  return async function (): Promise<string> {
    if (await isExhausted(db, minCount)) {
      await refresh(db, getToken);
    }
    const id = await getGdriveIdFromStore(db);
    return id;
  };
}
