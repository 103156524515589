import React from "react";
import { Route, Routes } from "react-router-dom";
import { RequireSource } from "../RequireSource";
import { NotesMain } from "./NotesMain";
import { OrphanedNotesView } from "./OrphanNotesView";
import { RandomNotesView } from "./RandomNotesView";
import { StarredNotesView } from "./StarredNotesView";
import { SyncView } from "./SyncView";
import { TrashedNotesView } from "./TrashedNotesView";
import { ObjectDetail } from "./object-detail/ObjectDetail";

export function NotesApp() {
  return (
    <RequireSource>
      <Routes>
        <Route path="" element={<NotesMain />} />
        <Route path="note/:id" element={<ObjectDetail />} />
        <Route path="trashed" element={<TrashedNotesView />} />
        <Route path="starred" element={<StarredNotesView />} />
        <Route path="orphaned" element={<OrphanedNotesView />} />
        <Route path="random" element={<RandomNotesView />} />
        <Route path="sync" element={<SyncView />} />
      </Routes>
    </RequireSource>
  );
}
