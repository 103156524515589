import { merge, Observable, Subject } from "rxjs";
import { FileStateDb, FileRecord } from "./app-db";
import { FileState, FileAction, needsPush } from "./file-state";

export type WithId<T> = T & { id: string };
export interface FileStats {
  count: number;
  countNeedsPull: number;
}
export type StoreData = { [id: string]: FileState };
export interface FileStore {
  get(key: string): Promise<WithId<FileState> | undefined>;
  apply(key: string, action: FileAction): Promise<FileChange>;
  dump(): Promise<StoreData>;
  list(): Promise<string[]>;
  listSource(sourceId: string): Promise<string[]>;
  listNeedsPull(sourceId: string, limit: number): Promise<StoreData>;
  listNeedsPush(sourceId: string): Promise<StoreData>;
  listNeedsCrawl(sourceId: string): Promise<StoreData>;
  listContentType(contentType: string): Promise<string[]>;
  stats(): Promise<FileStats>;
}

function shouldPull(state: FileState): boolean {
  return state.pull.needsPull && !needsPush(state);
}

type KeyedState = [id: string, state: FileState];
function sortByPullAndFilter(items: KeyedState[], limit: number): KeyedState[] {
  items = items.slice();
  items.sort(
    ([_id1, state1], [_id2, state2]) =>
      state1.pull.requestedTimestamp.getTime() -
      state2.pull.requestedTimestamp.getTime()
  );
  const filtered = items.slice(0, limit);
  return filtered;
}

export class SimpleFileStore implements FileStore {
  private data: StoreData = {};
  constructor(data?: StoreData | undefined) {
    this.data = data ?? {};
  }
  async apply(id: string, action: FileAction): Promise<FileChange> {
    const before = this.data[id];
    const after = action(before);
    this.data[id] = after;
    const contentChanged =
      before?.data?.metadata.md5Checksum !== after.data?.metadata.md5Checksum;
    return { id, contentChanged };
  }
  async get(key: string): Promise<WithId<FileState> | undefined> {
    return { id: key, ...this.data[key] };
  }
  async list(): Promise<string[]> {
    return Object.keys(this.data);
  }
  async listSource(id: string): Promise<string[]> {
    const results: string[] = [];
    for (const [key, value] of Object.entries(this.data)) {
      if (value.source === id) {
        results.push(key);
      }
    }
    return results;
  }
  async listNeedsPull(sourceId: string, limit: number): Promise<StoreData> {
    const results: [string, FileState][] = [];
    for (const [key, value] of Object.entries(this.data)) {
      if (value.source === sourceId && shouldPull(value)) {
        results.push([key, value]);
      }
    }
    const filtered = sortByPullAndFilter(results, limit);
    return Object.fromEntries(filtered);
  }
  async listNeedsPush(sourceId: string): Promise<StoreData> {
    const results: [string, FileState][] = [];
    for (const [key, value] of Object.entries(this.data)) {
      if (value.source === sourceId && needsPush(value)) {
        results.push([key, value]);
      }
    }
    return Object.fromEntries(results);
  }
  async listNeedsCrawl(sourceId: string): Promise<StoreData> {
    const results: [string, FileState][] = [];
    for (const [key, value] of Object.entries(this.data)) {
      if (value.source === sourceId && value.crawl.needsCrawl) {
        results.push([key, value]);
      }
    }
    return Object.fromEntries(results);
  }
  async listContentType(contentType: string): Promise<string[]> {
    const results: string[] = [];
    for (const [key, value] of Object.entries(this.data)) {
      if (value.data?.metadata?.contentType === contentType) {
        results.push(key);
      }
    }
    return results;
  }
  async dump() {
    return this.data;
  }
  async stats(): Promise<FileStats> {
    throw new Error("Stats not implemented on simple store");
  }
}

export class DbFileStore implements FileStore {
  private db: FileStateDb;
  constructor(db: FileStateDb) {
    this.db = db;
  }
  async get(key: string): Promise<WithId<FileState> | undefined> {
    const object = await this.db.fileState.get(key);
    if (!object) {
      return object;
    }
    return unwrap(object);
  }
  async apply(id: string, action: FileAction): Promise<FileChange> {
    const db = this.db;
    let contentChanged = false;
    await db.transaction("readwrite", db.fileState, async () => {
      const before = await db.fileState.get(id);
      const after = action(before);
      await db.fileState.put(wrap(id, after));
      contentChanged =
        before?.data?.metadata.md5Checksum !== after.data?.metadata.md5Checksum;
    });
    return { id, contentChanged };
  }
  async list(): Promise<string[]> {
    const keys = await this.db.fileState.toCollection().keys();
    const results = [];
    for (const k of keys) {
      if (typeof k === "string") {
        results.push(k);
      }
    }
    return results;
  }
  async listNeedsPull(sourceId: string, limit: number): Promise<StoreData> {
    const results: [string, FileState][] = [];
    const records = await this.db.fileState
      .where({ source: sourceId, needsPull: 1 })
      .toArray();
    for (const record of records) {
      if (record.source === sourceId && shouldPull(record)) {
        results.push([record.id, unwrap(record)]);
      }
    }
    const filtered = sortByPullAndFilter(results, limit);
    return Object.fromEntries(filtered);
  }
  async listNeedsPush(sourceId: string): Promise<StoreData> {
    const results: StoreData = {};
    const records = await this.db.fileState
      .where({ source: sourceId, needsPush: 1 })
      .toArray();
    for (const record of records) {
      if (record.source === sourceId && needsPush(record)) {
        results[record.id] = unwrap(record);
      }
    }
    return results;
  }
  async listNeedsCrawl(sourceId: string): Promise<StoreData> {
    const results: StoreData = {};
    const records = await this.db.fileState
      .where({ source: sourceId, needsCrawl: 1 })
      .toArray();
    for (const record of records) {
      if (record.source === sourceId && record.crawl.needsCrawl) {
        results[record.id] = unwrap(record);
      }
    }
    return results;
  }
  async listSource(id: string): Promise<string[]> {
    const records = await this.db.fileState.toArray();
    const results = [];
    for (const record of records) {
      if (record.source === id) {
        results.push(record.id);
      }
    }
    return results;
  }
  async listContentType(contentType: string): Promise<string[]> {
    const records = await this.db.fileState
      .where("contentType")
      .equals(contentType)
      .primaryKeys();
    const isString = (x: any): x is string => typeof x === "string";
    return records.filter(isString);
  }
  async dump(): Promise<StoreData> {
    const objects = await this.db.fileState.toArray();
    const result: StoreData = {};
    for (const object of objects) {
      const { id, ...rest } = object;
      result[id] = rest;
    }
    return result;
  }
  async stats() {
    const count = await this.db.fileState.count();
    const countNeedsPull = await this.db.fileState
      .where({ needsPull: 1 })
      .count();
    return { count, countNeedsPull };
  }
}

export interface FileChange {
  id: string;
  contentChanged: boolean;
}

export type ObservableFileStore = FileStore & {
  observable: Observable<FileChange>;
};

export function broadcastChanges(
  store: ObservableFileStore,
  channel: BroadcastChannel
) {
  return store.observable.subscribe((change) => {
    channel.postMessage(change);
  });
}

export function receiveChanges(
  store: ObservableFileStore,
  channel: BroadcastChannel
): ObservableFileStore {
  const subject = new Subject<FileChange>();
  const handler = (message: MessageEvent<any>) => subject.next(message.data);
  channel.addEventListener("message", handler);
  const observable = merge(store.observable, subject);
  return {
    ...store,
    observable,
  };
}

export function observe(store: FileStore): ObservableFileStore {
  const subject = new Subject<FileChange>();
  return {
    get(key) {
      return store.get(key);
    },
    async apply(key, action) {
      const change = await store.apply(key, action);
      subject.next(change);
      return change;
    },
    dump() {
      return store.dump();
    },
    list() {
      return store.list();
    },
    listSource(sourceId) {
      return store.listSource(sourceId);
    },
    listContentType(contentType) {
      return store.listContentType(contentType);
    },
    listNeedsPull(sourceId, limit) {
      return store.listNeedsPull(sourceId, limit);
    },
    listNeedsPush(sourceId) {
      return store.listNeedsPush(sourceId);
    },
    listNeedsCrawl(sourceId) {
      return store.listNeedsCrawl(sourceId);
    },
    stats() {
      return store.stats();
    },
    observable: subject,
  };
}

function unwrap(record: FileRecord): WithId<FileState> {
  const {
    needsPush: _needsPush,
    needsPull: _needsPull,
    needsCrawl: _needsCrawl,
    contentType: _contentType,
    ...rest
  } = record;
  return rest;
}

function wrap(id: string, state: FileState): FileRecord {
  return {
    ...state,
    needsPush: needsPush(state) ? 1 : 0,
    needsPull: state.pull.needsPull ? 1 : 0,
    needsCrawl: state?.crawl.needsCrawl ? 1 : 0,
    contentType: state?.data?.metadata?.contentType,
    id,
  };
}

export const channelName = "file-store";
