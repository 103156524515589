import React from "react";

export function LoadErrorScreen({ message }: { message: string }) {
  return (
    <>
      <h1>Error Initializing App</h1>
      <p>Could not start the app:</p>
      <pre>{message}</pre>
    </>
  );
}
