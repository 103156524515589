import { Chip, Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useRef } from "react";
import YouTube from "react-youtube";
import { FpApi, ObjectDetails } from "../fp-api";
import { Attachments } from "./content/Attachments";
import { DetailImage } from "./content/DetailImage";
import { FileContent } from "./content/FileContent";
import { ObjectText } from "./content/ObjectText";

export function ObjectContentView({
  object,
  api,
}: {
  object: ObjectDetails;
  api: FpApi;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const ref = useRef<HTMLDivElement | null>(null);
  const youTubeId = object.links.find((x) => x.youTubeId)?.youTubeId;
  const id = object.id;
  const terms = api.getSearchTermsForObjectId(id);
  useEffect(() => {
    const elt = ref.current;
    async function attachFiles(files: FileList) {
      const arr = Array.from(files);
      for (const file of arr) {
        await api.attachFile(id, file);
      }
    }
    async function handlePaste(e: ClipboardEvent) {
      if (e.clipboardData?.files) {
        attachFiles(e.clipboardData.files);
      }
    }
    elt?.addEventListener("paste", handlePaste);
    return () => elt?.removeEventListener("paste", handlePaste);
  }, [api, id]);
  return (
    <Grid ref={ref} container rowSpacing={1} padding={1} sx={{ maxWidth: 600 }}>
      {object.type === "file" && <FileContent object={object} api={api} />}
      {object.mainImage && object.mainImage.type === "folderPlace" && (
        <Grid item xs={12}>
          <DetailImage id={object.mainImage.image.imageId} />
        </Grid>
      )}
      {youTubeId && (
        <Grid item xs={12}>
          <YouTube videoId={youTubeId} opts={{ width: "100%" }} />
        </Grid>
      )}
      <Grid item xs={12}>
        <ObjectText object={object} api={api} />
      </Grid>
      {object.type !== "file" && (
        <Grid item xs={12}>
          <Attachments object={object} api={api} />
        </Grid>
      )}
      <Grid item xs={12}>
        <Stack direction="row" spacing={1}>
          {terms.map((x) => (
            <Chip
              key={x.id}
              label={x.term}
              onDelete={() => api.removeSearchTerm(x.objectId, x.term)}
            />
          ))}
        </Stack>
      </Grid>
      {isMobile && (
        <Grid item xs={12} sx={{ height: 72 }}>
          {/* for FAB */}
        </Grid>
      )}
    </Grid>
  );
}
