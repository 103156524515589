import { Star, StarBorder } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { FpApi, ObjectDetails } from "../../fp-api";

export function StarControl({
  object,
  api,
}: {
  object: ObjectDetails;
  api: FpApi;
}) {
  const { isStarred } = object;
  const icon = isStarred ? <Star /> : <StarBorder />;
  const handleClick = () => api.setObjectStarred(object.id, !isStarred);
  return (
    <Tooltip title={isStarred ? "Click to star" : "Click to remove star"}>
      <IconButton onClick={handleClick}>{icon}</IconButton>
    </Tooltip>
  );
}
