import { Skeleton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { usePullFile } from "../hooks";

export function CellImage({
  id,
  width,
  height,
  thumbnailSize,
}: {
  id: string;
  width: number | string;
  height: number | string;
  thumbnailSize?: number;
}) {
  const thumb = useThumbnail(id, thumbnailSize ?? 60);
  const { pulled: fileData } = usePullFile(id);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const pulled = !!fileData;
  const skel = (
    <Skeleton
      animation="wave"
      variant="rectangular"
      width={width}
      height={height}
    />
  );
  const showSkel = !thumb && (!loaded || error);
  const src = pulled ? `/files/${id}#${!!pulled}` : thumb;
  return (
    <>
      {showSkel && skel}
      <img
        ref={imgRef}
        src={src}
        onError={() => setError(true)}
        onLoad={async () => {
          setLoaded(true);
        }}
        style={{
          objectFit: "cover",
          height,
          width,
          display: showSkel ? "none" : "inline",
        }}
      />
    </>
  );
}

const thumbNails: Record<string, string> = {};
function useThumbnail(id: string, size: number) {
  const key = `${id}:${size}`;
  const { pulled: fileData } = usePullFile(id);
  const content = fileData?.content;
  const contentType = fileData?.metadata.contentType;
  useEffect(() => {
    async function run() {
      if (!content || !contentType) {
        return;
      }
      const thumb = await createTinyThumbnail(content, contentType, size);
      thumbNails[key] = thumb;
    }
    run();
  }, [content, contentType, fileData, id, key, size]);
  const result = thumbNails[key];
  return result;
}

async function createTinyThumbnail(
  arrayBuffer: ArrayBuffer,
  contentType: string,
  baseSize: number
): Promise<string> {
  return new Promise((resolve, reject) => {
    const devicePixelRatio = window.devicePixelRatio || 1; // Get the device pixel ratio, default to 1
    const blob = new Blob([arrayBuffer], { type: contentType });
    const url = URL.createObjectURL(blob);
    const img = new Image();

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = baseSize * devicePixelRatio; // Scale canvas width
      canvas.height = baseSize * devicePixelRatio; // Scale canvas height

      const ctx = canvas.getContext("2d");
      if (!ctx) {
        reject("Unable to get canvas context");
        return;
      }

      ctx.scale(devicePixelRatio, devicePixelRatio); // Scale the context to compensate for the increased canvas size

      // Calculate the scaling and positioning for "object-fit: cover"
      const scale = Math.max(baseSize / img.width, baseSize / img.height);
      const x = baseSize / 2 - (img.width / 2) * scale;
      const y = baseSize / 2 - (img.height / 2) * scale;

      ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
      URL.revokeObjectURL(url);

      // Adjust the compression quality for smaller file size
      const compressionQuality = 0.01;
      const thumbnailBase64 = canvas.toDataURL(
        "image/webp",
        compressionQuality
      );

      resolve(thumbnailBase64);
    };

    img.onerror = (error) => {
      URL.revokeObjectURL(url);
      reject(error);
    };

    img.src = url;
  });
}
