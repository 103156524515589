import { GDriveChangeWatcherDb } from "../app-db";
import { getDriveChanges } from "../google-drive/google-drive-change-watcher";
import { SourceType } from "../source-state";
import { SourceStore } from "../source-store";
import { PeriodicBackgroundJob } from "./background-jobs";
import { GDriveFolder } from "../google-drive/google-folder-picker";
import {
  gdriveGetMetadata,
  GetMetadata,
} from "../google-drive/google-drive-api";
import { folderContainsFileClient } from "../google-drive/google-drive-change-watcher";
import { requestPull } from "../file-state";
import { Logger, prefix } from "../logging";
import { GoogleAuth } from "../google-drive/google-drive-auth";
import { AppFileSystem } from "../app-file-system";

async function gdriveSources(store: SourceStore) {
  const sourceIds = await store.list();
  const gdriveSources: string[] = [];
  for (const sourceId of sourceIds) {
    const source = await store.get(sourceId);
    if (source?.type === SourceType.gdrive && !source.isDeleted) {
      gdriveSources.push(sourceId);
    }
  }
  return gdriveSources;
}

function sourceContains(
  store: SourceStore,
  getMetadata: GetMetadata,
  logger: Logger
) {
  const folderContainsFile = folderContainsFileClient(getMetadata, logger);
  return async function (sourceId: string, fileId: string) {
    const source = await store.get(sourceId);
    if (!source) {
      return false;
    }
    const folder = source.config as GDriveFolder;
    return folderContainsFile(folder.id, fileId);
  };
}

export function googleChangeWatcherJob(
  auth: GoogleAuth,
  fs: AppFileSystem,
  db: GDriveChangeWatcherDb,
  logger: Logger
) {
  logger = prefix(logger, "[Watch]");
  return new PeriodicBackgroundJob({
    name: "watch",
    job: async () => {
      if (!navigator.onLine) {
        return;
      }
      const sourceIds = await gdriveSources(fs.sources);
      if (!sourceIds.length) {
        return;
      }
      const creds = await auth();
      const fileIds = await getDriveChanges(creds.token, db, logger);
      const contains = sourceContains(
        fs.sources,
        gdriveGetMetadata(async () => creds.token),
        logger
      );
      for (const fileId of fileIds) {
        for (const sourceId of sourceIds) {
          if (await contains(sourceId, fileId)) {
            await fs.files.apply(fileId, requestPull(sourceId, new Date()));
          }
        }
      }
    },
    delayMs: 5000,
  });
}
