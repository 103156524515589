import { BindParams, QueryExecResult, SqlValue } from "sql.js";
import {
  AddColumnMessage,
  AddColumnOp,
  CreateTableMessage,
  CreateTableOp,
  DeleteRowsMessage,
  DeleteRowsOp,
  DropColumnMessage,
  DropColumnOp,
  DropTableMessage,
  DropTableOp,
  UpsertTableDataMessage,
  UpsertTableDataOp,
} from "./operations";

export const defaultIdName = "id" as const;
export type ColType = "integer" | "real" | "text" | "blob" | "null";
export function toColType(type: string): ColType | undefined {
  const lc = type.toLocaleLowerCase();
  switch (lc) {
    case "integer":
    case "real":
    case "text":
    case "blob":
    case "null":
      return lc;
  }
}

export interface ColDef {
  name: string;
  type: ColType;
}

export interface Schema {
  getColumns(): string[];
  getColumnDef(name: string): ColDef;
}
export function schemaFrom(colDefs: ColDef[]): Schema {
  return {
    getColumns() {
      return colDefs.map((x) => x.name);
    },
    getColumnDef(name) {
      const def = colDefs.find((x) => x.name === name);
      if (!def) {
        throw new Error(`Column not found: ${name}`);
      }
      return def;
    },
  };
}

export interface DbDump {
  [table: string]: {
    schema: {
      [name: string]: ColType;
    };
    data: TableData;
  };
}

export interface TableData {
  [rowId: string]: RowData;
}
export function projectTableData(
  data: TableData,
  columns: string[]
): TableData {
  const result: TableData = {};
  const include = new Set(columns);
  for (const rowId of Object.keys(data)) {
    result[rowId] = {};
    for (const column of Object.keys(data[rowId])) {
      if (include.has(column)) {
        result[rowId][column] = data[rowId][column];
      }
    }
  }
  return result;
}
export function selectTableData(data: TableData, rowIds: string[]): TableData {
  const include = new Set(rowIds);
  const entries = Object.entries(data).filter(([rowId]) => include.has(rowId));
  return Object.fromEntries(entries);
}

export interface RowData {
  [column: string]: SqlValue;
}

export interface ColumnData {
  [rowId: string]: SqlValue;
}

export interface Db {
  getTables(): string[];
  getSchema(table: string): Schema;
  getTableData(table: string, rowIds?: string[]): TableData;
  getColumnData(table: string, column: string): TableData;
  getRowIds(table: string): string[];
}

export type WritableDb = Db & {
  createTable(table: string, schema: Schema): boolean;
  dropTable(table: string): boolean;
  addColumn(table: string, column: ColDef): boolean;
  dropColumn(table: string, column: string): boolean;
  upsertTableData(table: string, data: TableData): boolean;
  updateColumnData(table: string, column: string, data: ColumnData): boolean;
  deleteRows(table: string, rowIds: string[]): boolean;
  updateRow(table: string, rowId: string, setList: RowData): boolean;
};

export type VirtualDb = Db & {
  updateAt(timestamp: number): WritableDb;
  getMaxTimestamp(): number;
};

export type PhysicalDb = WritableDb & {
  exec(query: string, bindParams?: BindParams): QueryExecResult[];
};

export interface ConflictFreeDb {
  readonly virtualDb: VirtualDb;
  readonly physicalDb: WritableDb;
  apply(change: Change): boolean;
  query(sql: string, params?: BindParams): QueryExecResult[];
}

export type OpMessage =
  | AddColumnMessage
  | CreateTableMessage
  | DeleteRowsMessage
  | DropColumnMessage
  | DropTableMessage
  | UpsertTableDataMessage;

export function deserializeOp(message: OpMessage): Operation {
  switch (message.type) {
    case "addColumn":
      return new AddColumnOp(message.table, message.colDef);
    case "createTable":
      return new CreateTableOp(message.table, message.columns);
    case "deleteRows":
      return new DeleteRowsOp(message.table, message.rowIds);
    case "dropColumn":
      return new DropColumnOp(message.table, message.column);
    case "dropTable":
      return new DropTableOp(message.table);
    case "upsertTableData":
      return new UpsertTableDataOp(message.table, message.data);
    default:
      throw new Error("Unexpected type");
  }
}

export interface AffectedRow {
  table: string;
  row: string;
}

export interface Operation {
  apply(db: WritableDb): boolean;
  applyAndReconcile(virtualDb: WritableDb, physicalDb: WritableDb): boolean;
  serialize(): OpMessage;
  affectedRows(): AffectedRow[];
}

export interface Change {
  operation: Operation;
  timestamp: number;
}

export interface ChangeMessage {
  operation: OpMessage;
  timestamp: number;
}

export function deserializeChange(message: ChangeMessage): Change {
  return {
    timestamp: message.timestamp,
    operation: deserializeOp(message.operation),
  };
}

export function serializeChange(change: Change): ChangeMessage {
  return {
    timestamp: change.timestamp,
    operation: change.operation.serialize(),
  };
}

export function dumpDb(db: Db): DbDump {
  const dump: DbDump = {};
  for (const table of db.getTables()) {
    dump[table] = { schema: {}, data: {} };
    const schema = db.getSchema(table);
    for (const column of schema.getColumns()) {
      dump[table].schema[column] = schema.getColumnDef(column).type;
    }
    dump[table].data = db.getTableData(table);
  }
  return dump;
}

const writeCmd = /^\s*(update|insert|create table|drop table|alter|delete)\b/i;
export function looksLikeWrite(query: string) {
  return !!writeCmd.exec(query);
}
