import { Box, ListItem, ListItemButton } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import {
  SelectionState,
  useArrowKeyNav,
  useSelectionState,
} from "./notes-hooks";
import { SearchInput } from "./views/editor/Editor";
import { ItemContent } from "./views/editor/ListEditor";

export interface ListViewProps<T> {
  getObjects: (query: string, page: number) => T[];
  onSelect: (object: T) => void;
  getPrimaryText: (object: T) => string;
  getSecondaryText?: (object: T) => string;
  placeholder?: string;
  noHits?: (query: string) => React.ReactNode;
}

export function useListViewState<T>(
  getObjects: (query: string, page: number) => T[]
) {
  const [input, setInputBase] = useState("");
  const [page, setPage] = useState(0);
  const [objects, setObjects] = useState<T[]>([]);
  const setInput = useCallback(
    (input: string) => {
      const trimmed = input.trim();
      setInputBase(input);
      setPage(0);
      setObjects(getObjects(trimmed, 0));
    },
    [getObjects]
  );
  const loadNextPage = useCallback(() => {
    setPage(page + 1);
    setObjects((prev) => [...prev, ...getObjects(input.trim(), page + 1)]);
  }, [getObjects, input, page]);
  useEffect(() => setInput(""), [setInput]);
  const selection = useSelectionState(objects);
  return { objects, setInput, input, loadNextPage, selection };
}

export function ListView<T>(props: ListViewProps<T>) {
  const { placeholder, noHits, getObjects } = props;
  const { objects, setInput, input, loadNextPage, selection } =
    useListViewState(getObjects);
  return (
    <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
      <Box sx={{ p: 1, width: "100%" }}>
        <SearchInput
          placeholder={placeholder}
          value={input}
          onChange={setInput}
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <ListViewContent
          input={input}
          objects={objects}
          noHits={noHits}
          selection={selection}
          loadNextPage={loadNextPage}
          {...props}
        />
      </Box>
    </Box>
  );
}

export interface ListViewContentProps<T> {
  onSelect: (object: T) => void;
  getPrimaryText: (object: T) => string;
  getSecondaryText?: (object: T) => string;
  objects: T[];
  loadNextPage: () => void;
  noHits?: (query: string) => React.ReactNode;
  input: string;
  selection: SelectionState;
}
export function ListViewContent<T>(props: ListViewContentProps<T>) {
  const { objects, noHits, input, selection, onSelect } = props;
  useArrowKeyNav({
    objects,
    selection,
    onSelect,
  });
  return (
    <>
      {objects.length > 0 && <ListItems {...props} />}
      {objects.length === 0 && noHits && noHits(input)}
    </>
  );
}

interface ItemData<T> {
  objects: T[];
  getPrimaryText: (object: T) => string;
  getSecondaryText?: (object: T) => string;
  onSelect: (object: T) => void;
  selectedIndex: number | undefined;
}

export function ListItems<T>({
  objects,
  onSelect,
  getPrimaryText,
  getSecondaryText,
  loadNextPage,
  selection,
}: ListViewContentProps<T>) {
  useArrowKeyNav({
    objects,
    selection,
    onSelect,
  });
  const selectedIndex = selection.index;
  const itemData = useMemo(
    () => ({
      objects,
      onSelect,
      getPrimaryText,
      getSecondaryText,
      selectedIndex,
    }),
    [objects, onSelect, getPrimaryText, getSecondaryText, selectedIndex]
  );
  return (
    <AutoSizer>
      {({ height, width }: { height: number; width: number }) => (
        <InfiniteLoader
          isItemLoaded={(i) => i < objects.length - 1}
          itemCount={objects.length}
          loadMoreItems={loadNextPage}
        >
          {({ onItemsRendered, ref }) => (
            <FixedSizeList<ItemData<T>>
              ref={ref}
              onItemsRendered={onItemsRendered}
              itemData={itemData}
              height={height}
              itemCount={itemData.objects.length}
              itemSize={60}
              width={width}
            >
              {Row}
            </FixedSizeList>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
}

function Row<T>({
  index,
  style,
  data,
}: {
  index: number;
  style: React.CSSProperties;
  data: ItemData<T> | undefined;
}) {
  if (!data) {
    return null;
  }
  const { objects, onSelect, getPrimaryText, getSecondaryText, selectedIndex } =
    data;
  const object = objects[index];
  const sx =
    selectedIndex === undefined
      ? { height: "100%" }
      : { "&:hover": { backgroundColor: "transparent" }, height: "100%" };
  return (
    <ListItem style={style} disablePadding>
      <ListItemButton
        selected={selectedIndex === index}
        onClick={() => onSelect(object)}
        sx={sx}
        dense
      >
        <ItemContent
          primaryText={getPrimaryText(object)}
          secondaryText={getSecondaryText ? getSecondaryText(object) : ""}
        />
      </ListItemButton>
    </ListItem>
  );
}
