import { LogDb, LogRecord } from "./app-db";
import { shortId } from "./uuid";

export interface LogStore {
  list(): Promise<LogRecord[]>;
  append(message: string): void;
  cleanup(limitSeconds: number): Promise<void>;
  clear(): Promise<void>;
}

export class DbLogStore implements LogStore {
  db: LogDb;
  constructor(db: LogDb) {
    this.db = db;
  }
  append(message: string, timestamp?: Date): void {
    const id = shortId();
    timestamp = timestamp ?? new Date();
    const record = { id, message, timestamp };
    this.addRecord(record);
  }
  list(): Promise<LogRecord[]> {
    return this.db.log.toArray();
  }
  async cleanup(limitSeconds: number): Promise<void> {
    const threshold = new Date();
    threshold.setSeconds(threshold.getSeconds() - limitSeconds);
    await this.db.log.where("timestamp").below(threshold).delete();
  }
  async clear(): Promise<void> {
    await this.db.log.toCollection().delete();
  }
  private async addRecord(record: LogRecord): Promise<void> {
    try {
      await this.db.log.put(record);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Could not add log record", error);
    }
  }
}
