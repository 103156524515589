import React from "react";
import { FullScreenDropzone } from "../../FileDropZone";
import { FpApi, ObjectDetails } from "../../fp-api";
import { AttachmentList } from "./AttachmentList";

export function Attachments({
  object,
  api,
}: {
  object: ObjectDetails;
  api: FpApi;
}) {
  async function onDrop(files: File[]) {
    for (const file of files) {
      await api.attachFile(object.id, file);
    }
  }
  return (
    <FullScreenDropzone onDrop={onDrop}>
      <AttachmentList
        objectId={object.id}
        attachments={object.attachments}
        onDelete={(id) => api.deleteAttachment(id)}
      />
    </FullScreenDropzone>
  );
}
