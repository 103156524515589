import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { useFpApi } from "../../notes-hooks";

export function SqlQueryDropdown({
  value,
  onChange,
}: {
  value: string | undefined;
  onChange: (value: string) => void;
}) {
  const api = useFpApi();
  const queries = api.getSqlQueries();
  return (
    <FormControl>
      <InputLabel>Query</InputLabel>
      <Select
        size="small"
        value={value ?? ""}
        onChange={(e) => onChange(e.target.value as string)}
        label="Query"
      >
        {queries.map((q) => (
          <MenuItem key={q.id} value={q.id}>
            {q.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
