import Search from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Fade,
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useOnClickOutside } from "usehooks-ts";
import { appBarZ } from "../../../LayoutAppBar";
import { EditorState, useArrowKeyNav } from "../../notes-hooks";

export interface EditorLayoutProps<T> {
  inputPlaceholder?: string;
  state: EditorState<T>;
}

export function EditorLayout<T>(
  props: React.PropsWithChildren<EditorLayoutProps<T>>
) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  if (isMobile && props.state.isEditing) {
    return (
      <Fade in>
        <Box
          sx={{
            position: "absolute",
            left: 0,
            top: 0,
            zIndex: appBarZ + 100,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            paddingTop: 1,
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
            <EditorLayoutContent {...props} />
          </Box>
        </Box>
      </Fade>
    );
  } else {
    return <EditorLayoutContent {...props} />;
  }
}

export function EditorLayoutContent<T>({
  inputPlaceholder,
  state,
  children,
}: React.PropsWithChildren<EditorLayoutProps<T>>) {
  const { setEditMode, setViewMode, setInput, input, isEditing, inputRef } =
    state;
  useEffect(() => {
    if (inputRef.current && !isEditing) {
      inputRef.current.blur();
    }
  }, [inputRef, isEditing]);
  const containerRef = useRef(null);
  useOnClickOutside(containerRef, () => {
    if (isEditing) {
      setViewMode();
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  });
  useHotkeys(
    "esc",
    () => {
      if (isEditing) {
        setViewMode();
      }
    },
    { enableOnFormTags: ["input"] },
    [isEditing, setViewMode]
  );
  useEffect(() => {
    if (isEditing) {
      inputRef?.current?.focus();
    }
  }, [isEditing, inputRef]);
  const theme = useTheme();
  return (
    <Box
      ref={containerRef}
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 1,
          paddingLeft: 1,
          paddingRight: 1,
        }}
      >
        <SearchInput
          inputRef={inputRef}
          placeholder={inputPlaceholder}
          onClick={setEditMode}
          onChange={setInput}
          value={input}
        />
        <Button
          sx={{ flexGrow: 1 }}
          onClick={() => (isEditing ? setViewMode() : setEditMode())}
        >
          {isEditing ? "Done" : "Edit"}
        </Button>
      </Box>
      <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
        {children}
      </Box>
    </Box>
  );
}

export function SearchInput({
  value,
  onClick,
  onChange,
  inputRef,
  placeholder,
}: {
  value: string;
  onClick?: () => void;
  onChange?: (value: string) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
  placeholder?: string;
}) {
  function handleClear() {
    onChange && onChange("");
    inputRef?.current?.focus();
  }
  return (
    <TextField
      size="small"
      variant="outlined"
      fullWidth
      value={value}
      onClick={onClick}
      onChange={(e) => {
        onChange && onChange(e.target.value);
      }}
      inputRef={inputRef}
      placeholder={placeholder}
      InputProps={{
        autoComplete: "off",
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: value && (
          <Button variant="text" size="small" onClick={handleClear}>
            Clear
          </Button>
        ),
      }}
    />
  );
}

export type EditorKeyboardNavProps<T> = EditorState<T> & {
  onSelect: (object: T) => void;
};

export function useEditorKeyboardNav<T>(state: EditorKeyboardNavProps<T>) {
  const {
    isEditing,
    setEditMode,
    inputRef,
    items,
    addObject,
    selection,
    onSelect,
  } = state;
  useArrowKeyNav({
    vimMode: !isEditing,
    objects: items,
    selection,
    onSelect: (item) => onSelect(item.object),
  });
  useHotkeys(
    "e",
    (e) => {
      if (isEditing) {
        return;
      }
      setEditMode();
      e.preventDefault();
      // put in timeout, otherwise keyboard shortcut ends up in text box
      setTimeout(() => inputRef.current?.focus(), 0);
    },
    [isEditing, setEditMode, inputRef]
  );
  useHotkeys(
    "enter",
    () => {
      if (isEditing && items.length === 0) {
        addObject();
      }
    },
    { enableOnFormTags: ["input"] },
    [addObject, items, isEditing]
  );
}
