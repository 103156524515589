import { Delete, Download } from "@mui/icons-material";
import { IconButton, List, ListItem, ListItemIcon } from "@mui/material";
import React from "react";
import ReactAudioPlayer from "react-audio-player";
import { usePullFile } from "../../../hooks";
import { AttachmentListItem } from "../../db-attachments";
import { AddAttachment } from "./AddAttachment";

export function AttachmentList({
  objectId,
  attachments,
  onDelete,
}: {
  objectId: string;
  onDelete: (id: string) => void;
  attachments: ReadonlyArray<AttachmentListItem>;
}) {
  return (
    <>
      <List>
        {attachments.map((attachment) => (
          <ListItem
            key={attachment.attachmentId}
            secondaryAction={
              <IconButton
                edge="end"
                onClick={() => onDelete(attachment.attachmentId)}
              >
                <Delete />
              </IconButton>
            }
          >
            <ListItemIcon>
              <DownloadFileButton
                id={attachment.fileId}
                name={attachment.title}
              />
            </ListItemIcon>
            <Attachment attachment={attachment} />
          </ListItem>
        ))}
      </List>
      <AddAttachment objectId={objectId} />
    </>
  );
}

function DownloadFileButton({
  id,
  name,
}: {
  id: string | undefined;
  name: string | undefined;
}) {
  const { pulled } = usePullFile(id);
  if (!id) {
    return null;
  }
  function download() {
    if (!pulled) {
      return;
    }
    const blob = new Blob([pulled.content], {
      type: pulled.metadata.contentType,
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("display", "none");
    a.setAttribute("download", name ?? "file");
    a.setAttribute("href", url);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
  return (
    <IconButton
      aria-label="download file"
      edge="start"
      onClick={download}
      disabled={!pulled}
    >
      <Download />
    </IconButton>
  );
}

function AudioAttachment({ attachment }: { attachment: AttachmentListItem }) {
  return <ReactAudioPlayer src={`/files/${attachment.fileId}`} controls />;
}

function OtherAttachment({ attachment }: { attachment: AttachmentListItem }) {
  return <>{attachment.title}</>;
}

function Attachment({ attachment }: { attachment: AttachmentListItem }) {
  if (attachment.contentType.startsWith("audio/")) {
    return <AudioAttachment attachment={attachment} />;
  } else {
    return <OtherAttachment attachment={attachment} />;
  }
}
