import { Typography } from "@mui/material";
import React from "react";
import { LayoutAppBar } from "./LayoutAppBar";

export function DefaultAppBar({ onMenuClick }: { onMenuClick: () => void }) {
  return (
    <LayoutAppBar onMenuClick={onMenuClick}>
      <Typography variant="h6">Folder Place</Typography>
    </LayoutAppBar>
  );
}
