import { Search } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";

export function GoogleSearchAction({ query }: { query: string }) {
  const url = `https://www.google.com/search?q=${encodeURIComponent(query)}`;
  return (
    <Button
      variant="outlined"
      endIcon={<Search />}
      size="small"
      component="a"
      href={url}
      target="_blank"
    >
      Google
    </Button>
  );
}
