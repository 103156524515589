import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import React from "react";
import { FpApi, ObjectDetails } from "../fp-api";

export function SettingsView({
  object,
  api,
}: {
  object: ObjectDetails;
  api: FpApi;
}) {
  return (
    <Stack p={1}>
      <DefaultViewControl object={object} api={api} />
    </Stack>
  );
}

function DefaultViewControl({
  object,
  api,
}: {
  object: ObjectDetails;
  api: FpApi;
}) {
  const handleChange = (e: SelectChangeEvent) => {
    api.setDefaultView(object.id, e.target.value);
  };
  const label = "Default view";
  return (
    <FormControl>
      <InputLabel>{label}</InputLabel>
      <Select
        value={object.defaultViewId}
        onChange={handleChange}
        label={label}
      >
        {object.views.map((view) => (
          <MenuItem key={view.id} value={view.id}>
            {view.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
