import { AppBar, IconButton, Toolbar } from "@mui/material";
import React, { forwardRef } from "react";
import MenuIcon from "@mui/icons-material/Menu";

export const appBarZ = 100;
export const appBarHeight = 64;

function MenuButton({ onClick }: { onClick: () => void }) {
  return (
    <IconButton
      color="inherit"
      aria-label="open drawer"
      edge="start"
      onClick={onClick}
      sx={{ mr: 2, display: { sm: "none" } }}
    >
      <MenuIcon />
    </IconButton>
  );
}

export interface LayoutAppBarProps {
  onMenuClick: () => void;
}

export const LayoutAppBar = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<LayoutAppBarProps>
>((props, ref) => {
  const { onMenuClick } = props;
  return (
    <AppBar position="relative" sx={{ zIndex: appBarZ }} ref={ref}>
      <Toolbar>
        <MenuButton onClick={onMenuClick} />
        {props.children || null}
      </Toolbar>
    </AppBar>
  );
});
LayoutAppBar.displayName = "LayoutAppBar";
