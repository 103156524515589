import { ExpandLess, ExpandMore, Launch } from "@mui/icons-material";
import {
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GroupItem, ObjectDetails } from "../fp-api";
import { useDbQueries } from "../notes-hooks";

export function ExploreView({ id }: { id: string; object: ObjectDetails }) {
  const [expanded, setExpanded] = useState<{ [id: string]: boolean }>({});
  useEffect(() => setExpanded({}), []);
  const toggle = (id: string) =>
    setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
  const queries = useDbQueries();
  const navigate = useNavigate();
  const { groups, members } = queries.objectNeighborhood(id);
  const membersFor = (group: GroupItem) =>
    members.filter((x) => x.groupId === group.id);
  return (
    <>
      <List>
        {groups.map((group) => (
          <Fragment key={`group-${group.id}`}>
            <ListItem
              disablePadding
              secondaryAction={
                <IconButton
                  edge="end"
                  onClick={() => navigate(`/notes/note/${group.id}#explore`)}
                >
                  <Launch />
                </IconButton>
              }
            >
              <ListItemButton onClick={() => toggle(group.id)}>
                <ListItemIcon>
                  {expanded[group.id] ? <ExpandLess /> : <ExpandMore />}
                </ListItemIcon>
                <ListItemText primary={group.title} />
              </ListItemButton>
            </ListItem>
            <Collapse in={expanded[group.id]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {membersFor(group).map((item) => (
                  <ListItem
                    disablePadding
                    sx={{ pl: 4 }}
                    key={`group-${group.id}-item-${item.id}`}
                  >
                    <ListItemButton
                      onClick={() => navigate(`/notes/note/${item.id}#explore`)}
                    >
                      <ListItemText
                        primary={item.title}
                        secondary={item.text}
                        primaryTypographyProps={{
                          style: {
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          },
                        }}
                        secondaryTypographyProps={{
                          style: {
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          },
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </Fragment>
        ))}
      </List>
    </>
  );
}
