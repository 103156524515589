import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useParams } from "react-router-dom";
import { useFpApi, useObjectNavigate } from "../notes-hooks";
import { ObjectDetailHeader } from "./ObjectDetailHeader";
import { ObjectDetailTabs } from "./ObjectDetailTabs";

export function ObjectDetail() {
  const params = useParams();
  const id = params.id;
  useKeyboardNav(id);
  const api = useFpApi();
  useEffect(() => {
    id && api.dbObjects.touch(id);
  }, [id, api]);
  if (!id) {
    throw new Error("Object id missing");
  }
  const object = api.objectDetails(id);
  if (!object) {
    return <h1>Not found</h1>;
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <ObjectDetailHeader object={object} api={api} />
      <ObjectDetailTabs object={object} api={api} />
    </Box>
  );
}

function useTabHotkey(key: string, id: string | undefined, tab: string) {
  const navigate = useObjectNavigate();
  useHotkeys(key, () => id && navigate(id, tab), [id, tab, navigate]);
}

function useKeyboardNav(id: string | undefined) {
  useTabHotkey("t", id, "tags");
  useTabHotkey("r", id, "related");
  useTabHotkey("g", id, "gallery");
  useTabHotkey("c", id, "content");
}
