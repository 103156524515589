import { Box } from "@mui/material";
import React from "react";
import { PlaylistAction } from "../../YouTubePlaylist";
import { FpApi, ObjectDetails } from "../../fp-api";
import { DownloadAction } from "./DownloadAction";
import { GoogleSearchAction } from "./GoogleSearchAction";
import { LinkAction } from "./LinkAction";

export function ObjectActions({
  object,
  api,
}: {
  object: ObjectDetails;
  api: FpApi;
}) {
  const actions: JSX.Element[] = [];
  if (object.links.length > 0) {
    actions.push(<LinkAction url={object.links[0].url} />);
  }
  actions.push(<GoogleSearchAction query={object.title} />);
  const ids = api.relatedYouTubeIds(object.id);
  if (ids.length > 0) {
    actions.push(<PlaylistAction ids={ids} />);
  }
  if (object.type === "file") {
    actions.push(<DownloadAction object={object} />);
  }
  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      {actions.map((action, i) => (
        <Box key={i}>{action}</Box>
      ))}
    </Box>
  );
}
