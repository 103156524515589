import { Grid } from "@mui/material";
import React from "react";
import ReactAudioPlayer from "react-audio-player";
import { FpApi, ObjectDetails } from "../../fp-api";

export function FileContent({ object }: { object: ObjectDetails; api: FpApi }) {
  const att = object.attachments.find((x) => x.fileId === object.id);
  if (!att) {
    return null;
  }
  if (att.contentType.startsWith("audio/")) {
    return (
      <Grid item xs={12}>
        <ReactAudioPlayer src={`/files/${att.fileId}`} controls />
      </Grid>
    );
  } else {
    return null;
  }
}
