import { GoogleAuthDb } from "./app-db";
import {
  DbGoogleAuthStore,
  GoogleAuth,
  googleAuth,
  googleAuthImmediate,
  GoogleCredentials,
} from "./google-drive/google-drive-auth";
import { Logger } from "./logging";
import { SourceType } from "./source-state";

declare const GOOGLE_CLIENT_ID: string;

export type Credentials = GoogleCredentials;
export type Auth = () => Promise<Credentials>;
export type AuthByType = {
  [type in SourceType]: Auth;
};

function getGoogleAuth(db: GoogleAuthDb, logger: Logger) {
  const scopes = [
    "https://www.googleapis.com/auth/drive",
    "https://www.googleapis.com/auth/userinfo.email",
    "https://www.googleapis.com/auth/youtube.readonly",
  ];
  const scope = scopes.join(" ");
  const store = new DbGoogleAuthStore(db);
  const auth = googleAuth(GOOGLE_CLIENT_ID, scope, store, logger);
  return auth;
}

function getGoogleAuthImmediate(db: GoogleAuthDb, logger: Logger) {
  const scopes = [
    "https://www.googleapis.com/auth/drive",
    "https://www.googleapis.com/auth/userinfo.email",
    "https://www.googleapis.com/auth/youtube.readonly",
  ];
  const scope = scopes.join(" ");
  const store = new DbGoogleAuthStore(db);
  const auth = googleAuthImmediate(GOOGLE_CLIENT_ID, scope, store, logger);
  return auth;
}

export function initializeAuth(db: GoogleAuthDb, logger: Logger) {
  return new AuthManager(db, logger);
}

export class AuthManager {
  private googleAuth: GoogleAuth;
  private googleAuthImmediate: GoogleAuth;
  constructor(db: GoogleAuthDb, logger: Logger) {
    this.googleAuth = getGoogleAuth(db, logger);
    this.googleAuthImmediate = getGoogleAuthImmediate(db, logger);
  }
  getGoogle() {
    return this.googleAuth;
  }
  refreshGoogle() {
    return this.googleAuthImmediate();
  }
}
