import { Stack } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useDbQueries } from "../../notes-hooks";
import { createSavedQuery } from "../../saved-queries";
import { QueryParamForm } from "./QueryParamForm";
import { SqlQueryDropdown } from "./SqlQueryDropdown";

export function SqlQueryForm({
  onSubmit,
}: {
  onSubmit: (query: string, args: Record<string, string>) => void;
}) {
  const queries = useDbQueries();
  const [queryId, setQueryId] = useState<string | undefined>(undefined);
  const [query, setQuery] = useState<string | undefined>(undefined);
  const [args, setArgs] = useState<Record<string, string>>({});
  console.log({ args });
  function handleSelect(value: string) {
    setQueryId(value);
    setArgs({});
    const obj = value ? queries.getObject(value) : undefined;
    if (!obj) {
      return;
    }
    const query = createSavedQuery(obj);
    setQuery(query.text);

    if (query.parsed.valid) {
      const args = Object.fromEntries(
        query.parsed.parameters.map((x) => [x.name, ""])
      );
      setArgs(args);
      onSubmit(query.text, args);
      return;
    }
    onSubmit(query.text, args);
  }
  const setArg = useCallback(
    (name: string, value: string) => {
      setArgs((args) => ({ ...args, [name]: value }));
      if (query) {
        onSubmit(query, args);
      }
    },
    [args, onSubmit, query]
  );
  const argKeys = Object.keys(args).sort();
  return (
    <Stack spacing={1}>
      <SqlQueryDropdown value={queryId} onChange={handleSelect} />
      {argKeys.map((key) => (
        <QueryParamForm
          key={key}
          name={key}
          value={args[key]}
          onChange={(val) => setArg(key, val)}
        />
      ))}
    </Stack>
  );
}
