import { FpApiManager, initializeFpApis } from "../ui/notes/fp-api";
import { AppDb, deleteDb } from "./app-db";
import { AppFileSystem } from "./app-file-system";
import { DbManager } from "./app-sql-api";
import { AuthManager } from "./auth";
import { BackgroundJobRunner } from "./background-jobs/background-jobs";
import { encodeString, md5 } from "./file-state";
import {
  DbFileStore,
  broadcastChanges,
  channelName,
  observe,
  receiveChanges,
} from "./file-store";
import {
  GDriveFolderPicker,
  initializePicker,
} from "./google-drive/google-folder-picker";
import { SourceType } from "./source-state";

export class Controller {
  readonly auth: AuthManager;
  readonly db: AppDb;
  readonly sqlDbs: DbManager;
  readonly fpApis: FpApiManager;
  readonly fs: AppFileSystem;
  private picker: GDriveFolderPicker;
  private backgroundJobRunner: BackgroundJobRunner | undefined;
  constructor(
    db: AppDb,
    fs: AppFileSystem,
    auth: AuthManager,
    picker: GDriveFolderPicker,
    sqlDbs: DbManager,
    fpApis: FpApiManager
  ) {
    this.auth = auth;
    this.db = db;
    this.fs = fs;
    this.picker = picker;
    this.sqlDbs = sqlDbs;
    this.fpApis = fpApis;
  }
  async refreshAuth() {
    await this.auth.refreshGoogle();
  }
  async addFile(
    sourceId: string,
    name: string,
    content: ArrayBuffer,
    contentType: string
  ) {
    const now = new Date().getTime();
    const md5Checksum = md5(content);
    const data = {
      content,
      metadata: {
        isDeleted: false,
        name,
        contentType,
        createdAt: now,
        modifiedAt: now,
        parent: await this.fs.getParent(sourceId),
        md5Checksum,
      },
    };
    return this.fs.addFile(sourceId, data);
  }
  async addTextFile(sourceId: string, name: string, value: string) {
    const now = new Date().getTime();
    const content = encodeString(value);
    const md5Checksum = md5(content);
    const data = {
      content,
      metadata: {
        isDeleted: false,
        name,
        contentType: "text/plain",
        createdAt: now,
        modifiedAt: now,
        md5Checksum,
        parent: await this.fs.getParent(sourceId),
      },
    };
    await this.fs.addFile(sourceId, data);
  }
  async addSource(name: string, type: SourceType, config: unknown) {
    const sourceId = await this.fs.addSource(name, type, config);
    const db = await this.sqlDbs.createDb(sourceId);
    this.fpApis.create(sourceId, db, this.fs);
  }
  async deleteSource(sourceId: string) {
    await this.fs.deleteSource(sourceId);
    await this.sqlDbs.deleteDb(sourceId);
    this.fpApis.delete(sourceId);
  }
  async pickGDriveFolder() {
    return this.picker();
  }
  async deleteDatabase() {
    deleteDb(this.db);
  }
}

export function initializeFileStore(db: AppDb) {
  const fileStore = observe(new DbFileStore(db));
  const channel = new BroadcastChannel(channelName);
  broadcastChanges(fileStore, channel);
  return receiveChanges(fileStore, channel);
}

let singleton: Controller | undefined;
export async function initializeController(
  db: AppDb,
  fs: AppFileSystem,
  auth: AuthManager,
  sqlDbs: DbManager
) {
  const picker = initializePicker(auth);
  const fpApis = await initializeFpApis(db, fs, sqlDbs);
  singleton = new Controller(db, fs, auth, picker, sqlDbs, fpApis);
  return singleton;
}

export function getController() {
  if (!singleton) {
    throw new Error("controller not initialized");
  }
  return singleton;
}
