import { Container } from "@mui/material";
import React, { useState } from "react";
import { LogRecord } from "../data/app-db";
import { windowId } from "../data/background-jobs/background-jobs";
import { getController } from "../data/controller";
import { FileStats } from "./FileStats";
import { useLog } from "./hooks";

declare const BUILD_DATE: string;
declare const HAIKU_NAME: string;

export function DebugView() {
  const [query, setQuery] = useState("");
  const [negated, setNegated] = useState(false);
  const { log, clear } = useLog();
  if (!log) {
    return <>Loading...</>;
  }
  log.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
  const filter = (record: LogRecord) => {
    if (!query) {
      return true;
    }
    const match = record.message
      .toLocaleLowerCase()
      .includes(query.toLocaleLowerCase());
    if (negated) {
      return !match;
    } else {
      return match;
    }
  };
  const filtered = log.filter(filter);
  const lines = filtered.map((record) => (
    <tr key={record.id}>
      <td>{record.timestamp.toLocaleTimeString("en")}</td>
      <td>{record.message}</td>
    </tr>
  ));
  return (
    <Container>
      <div>{BUILD_DATE}</div>
      <div>{HAIKU_NAME}</div>
      <div>This Window: {windowId}</div>
      <button
        onClick={async () => {
          if (confirm("Really delete the database?")) {
            await getController().deleteDatabase();
          }
        }}
      >
        Delete Db
      </button>
      <FileStats />
      <button onClick={clear}>Clear Log</button>
      <label>
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        Filter
      </label>
      <label>
        <input
          type="checkbox"
          checked={negated}
          onChange={() => setNegated(!negated)}
        />
        Negate
      </label>
      <div>Count: {filtered.length}</div>
      <table>
        <thead>
          <tr>
            <th>Time</th>
            <th>Message</th>
          </tr>
        </thead>
        <tbody>{lines}</tbody>
      </table>
    </Container>
  );
}
