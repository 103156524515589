import React, { useMemo } from "react";
import { FpApi, ObjectListItem } from "../fp-api";
import { useFpApi, useObjectNavigate } from "../notes-hooks";
import { ListEditor, ListEditorProps } from "./editor/ListEditor";

export function TagsView({ id }: { id: string }) {
  const api = useFpApi();
  const navigate = useObjectNavigate();
  const props = useMemo(() => getProps(id, api, navigate), [id, api, navigate]);
  return <ListEditor {...props} />;
}

const pageSize = 100;
function getProps(
  id: string,
  api: FpApi,
  navigate: (id: string) => void
): ListEditorProps<ObjectListItem> {
  return {
    getObjects: ({ input, page, queries }) =>
      queries
        .searchObjects(input, pageSize, page * pageSize)
        .filter((x) => x.id !== id),
    getSelectedObjects: ({ input, queries }) =>
      queries.tagsOfObject(id, input).filter((x) => x.id !== id),
    getObjectId: (x) => x.id,
    getPrimaryText: (x) => x.title,
    getSecondaryText: (x) => x.text,
    toggle: (x) =>
      api.hasTag(id, x.id)
        ? api.deleteTagWhere(id, x.id)
        : api.addTag(id, x.id),
    add: ({ input, queries }) => {
      const tagObjectId = api.addNote(input, "");
      api.addTag(id, tagObjectId);
      return queries.getObject(tagObjectId);
    },
    getAddText: (query) => <>Add a new tag called &ldquo;{query}&rdquo;</>,
    navigate: (x) => navigate(x.id),
  };
}
