import { Stack } from "@mui/material";
import React from "react";
import { SqlView } from "../../../SqlView";
import { ObjectDetails } from "../../fp-api";

export function ObjectSqlView({ object }: { object: ObjectDetails }) {
  return (
    <Stack
      sx={{
        flexGrow: 1,
        overflow: "auto",
      }}
      spacing={1}
    >
      <SqlView object={object} />
    </Stack>
  );
}
