import React, { useEffect, useState } from "react";

export interface ClipboardText {
  type: "text";
  value: string;
}

export interface ClipboardData {
  type: "data";
  value: ArrayBuffer;
  contentType: string;
}

export type PasteObject = ClipboardText | ClipboardData;

async function parseClipboard(items: ClipboardItem[]): Promise<PasteObject[]> {
  let textItem: ClipboardText | undefined;
  const dataItems: ClipboardData[] = [];
  for (const item of items) {
    for (const type of item.types) {
      const blob = await item.getType(type);
      if (type === "text/plain") {
        const value = await blob.text();
        textItem = { type: "text", value };
      } else if (type !== "text/html") {
        const buf = await blob.arrayBuffer();
        dataItems.push({ type: "data", value: buf, contentType: type });
      }
    }
  }
  return textItem ? [textItem, ...dataItems] : dataItems;
}

export function useClipboard() {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<PasteObject[]>([]);
  useEffect(() => {
    let shouldRead = true;
    async function read() {
      try {
        const clipboard = await navigator.clipboard.read();
        if (!shouldRead) {
          return;
        }
        setItems(await parseClipboard(clipboard));
        setLoading(false);
      } catch {
        setItems([]);
      }
    }
    read();
    const interval = setInterval(read, 1000);
    return () => {
      clearInterval(interval);
      shouldRead = false;
    };
  }, []);
  return { items, loading };
}

function ClipboardTextButton({
  onClick,
}: {
  text: ClipboardText;
  onClick: () => void;
}) {
  return <button onClick={onClick}>Text from clipboard</button>;
}

function ClipboardDataButton({
  data,
  onClick,
}: {
  data: ClipboardData;
  onClick: () => void;
}) {
  const type = data.contentType;
  let displayType = "Data";
  if (type.startsWith("image/")) {
    displayType = "Image";
  } else if (type.startsWith("text/plain")) {
    displayType = "Text";
  }
  return <button onClick={onClick}>{displayType} from clipboard</button>;
}

function ClipboardButton({
  object,
  onClick,
}: {
  object: PasteObject;
  onClick: () => void;
}) {
  if (object.type === "data") {
    return <ClipboardDataButton onClick={onClick} data={object} />;
  } else if (object.type === "text") {
    return <ClipboardTextButton onClick={onClick} text={object} />;
  } else {
    return null;
  }
}

export function ClipboardButtons({
  onClick,
  filter,
}: {
  onClick: (items: PasteObject) => void;
  filter?: (item: PasteObject) => boolean;
}) {
  const { loading, items } = useClipboard();
  const filtered = filter ? items.filter(filter) : items;
  if (loading || filtered.length === 0) {
    return <></>;
  }
  return (
    <>
      {filtered.map((object, i) => (
        <ClipboardButton
          key={i}
          onClick={() => onClick(object)}
          object={object}
        />
      ))}
    </>
  );
}
