import Ajv from "ajv";
import {
  $Compiler,
  JSONSchema,
  wrapCompilerAsTypeGuard,
} from "json-schema-to-ts";
import { BindParams, SqlValue } from "sql.js";
import { AppSqlApi } from "../../data/app-sql-api";
import {
  ColDef,
  ColType,
  RowData,
  defaultIdName,
} from "../../data/conflict-free-db/core";
import {
  CreateTableOp,
  DeleteRowsOp,
  UpsertTableDataOp,
} from "../../data/conflict-free-db/operations";

type PartialWithId<T> = Partial<T> & { id: string };

export interface TableAccess<T> {
  table: string;
  select(query: string, params?: BindParams): T[];
  get(id: string): T | undefined;
  add(record: T): void;
  update(values: Partial<T>): void;
  delete(id: string): void;
}

export interface ObjectJSONSchema {
  type: string;
  properties: Record<string, JSONSchemaProperty>;
}

export interface JSONSchemaProperty {
  type: string;
  enum?: ReadonlyArray<any>;
}

export class TableAccessImpl<T extends object & { [defaultIdName]: string }>
  implements TableAccess<T>
{
  table: string;
  private schema: ObjectJSONSchema;
  private db: AppSqlApi;
  private toObject: (data: unknown) => T;
  constructor(
    table: string,
    schema: ObjectJSONSchema,
    toObject: (data: unknown) => T,
    db: AppSqlApi
  ) {
    this.schema = schema;
    this.table = table;
    this.db = db;
    this.toObject = toObject;
  }
  select(query: string, params?: BindParams): T[] {
    const [result] = this.db.queryShared(query, params);
    if (!result) {
      return [];
    }
    const rows = result.values;
    return rows.map((row) => this.rowToObject(result.columns, row));
  }
  get(id: string): T | undefined {
    const query = `SELECT * FROM ${this.table} WHERE ${defaultIdName} = :id`;
    const params = { ":id": id };
    return this.select(query, params)[0];
  }
  add(row: T): void {
    const record = Object.fromEntries(Object.entries(row));
    const schema = this.schema;
    const cols = Object.keys(schema.properties);
    const dataCols = cols.filter((x) => x !== defaultIdName);
    const rowData = Object.fromEntries(dataCols.map((x) => [x, record[x]]));
    const op = new UpsertTableDataOp(this.table, {
      [row.id]: rowData,
    });
    this.db.applyLocalOp(op);
  }
  delete(id: string) {
    const op = new DeleteRowsOp(this.table, [id]);
    this.db.applyLocalOp(op);
  }
  update(update: Partial<T>): void {
    if (!update.id) {
      throw new Error(`id is required`);
    }
    const cols = Object.keys(update).filter((x) => this.schema.properties[x]);
    const dataCols = cols.filter((x) => x !== defaultIdName);
    const record = Object.fromEntries(Object.entries(update));
    const rowData = Object.fromEntries(dataCols.map((x) => [x, record[x]]));
    const op = new UpsertTableDataOp(this.table, {
      [update.id]: rowData,
    });
    this.db.applyLocalOp(op);
  }
  private rowToObject(cols: string[], values: SqlValue[]): T {
    if (cols.length !== values.length) {
      throw new Error("Columns and values must have the same length");
    }
    const record: Record<string, string | number | boolean | null | undefined> =
      {};
    for (let i = 0; i < cols.length; i++) {
      const col = cols[i];
      if (!this.schema.properties[col]) {
        continue;
      }
      const property = this.schema.properties[col];
      const value = sqlToNative(values[i], property);
      if (value !== undefined) {
        record[col] = value;
      }
    }
    return this.toObject(record);
  }
}

export type NativeValue = string | number | boolean | null | undefined;
export function rowToNative<T extends ObjectJSONSchema>(schema: T) {
  return function (
    cols: string[],
    values: SqlValue[]
  ): Record<string, NativeValue> {
    if (cols.length !== values.length) {
      throw new Error("Columns and values must have the same length");
    }
    const record: Record<string, NativeValue> = {};
    for (let i = 0; i < cols.length; i++) {
      const col = cols[i];
      if (!schema.properties[col]) {
        continue;
      }
      const property = schema.properties[col];
      const value = sqlToNative(values[i], property);
      if (value !== undefined) {
        record[col] = value;
      }
    }
    return record;
  };
}
export function nativeToSql(nativeVal: NativeValue): SqlValue {
  if (nativeVal === null) {
    return nativeVal;
  }
  if (nativeVal === undefined) {
    return null;
  }
  switch (typeof nativeVal) {
    case "string":
      return nativeVal;
    case "number":
      return nativeVal;
    case "boolean":
      return nativeVal ? 1 : 0;
    default:
      throw new Error(`Unsupported sql type: ${typeof nativeVal}`);
  }
}

export function jsTypeToSqlType(jsType: string): ColType {
  switch (jsType) {
    case "string":
      return "text";
    case "number":
      return "real";
    case "integer":
      return "integer";
    case "boolean":
      return "integer";
    case "object":
      throw new Error("object type not supported");
    case "array":
      throw new Error("array type not supported");
    case "null":
      throw new Error("null type not supported");
    default:
      throw new Error("Unexpected type: " + jsType);
  }
}
function coerceToString(
  value: SqlValue,
  property: JSONSchemaProperty
): string | undefined {
  if (property.enum) {
    for (const enumVal of property.enum) {
      if (typeof value === "string" && enumVal === value) {
        return value;
      }
    }
  } else {
    return value?.toString();
  }
}
function coerceToNumber(value: SqlValue): number {
  if (typeof value === "number") {
    return value;
  } else {
    return 0;
  }
}
function coerceToInteger(value: SqlValue): number {
  if (typeof value === "number") {
    return Math.floor(value);
  } else {
    return 0;
  }
}
function coerceToBoolean(value: SqlValue): boolean | undefined {
  return value === null ? undefined : !!value;
}
function sqlToNative(value: SqlValue, property: JSONSchemaProperty) {
  const jsType = property.type;
  switch (jsType) {
    case "string":
      return coerceToString(value, property);
    case "number":
      return coerceToNumber(value);
    case "integer":
      return coerceToInteger(value);
    case "boolean":
      return coerceToBoolean(value);
    case "object":
      throw new Error("object type not supported");
    case "array":
      throw new Error("array type not supported");
    case "null":
      throw new Error("null type not supported");
    default:
      throw new Error("Unexpected type: " + jsType);
  }
}

const ajv = new Ajv();
export function validator<T extends JSONSchema>(schema: T) {
  const $compile: $Compiler = (schema) => ajv.compile(schema);
  const compile = wrapCompilerAsTypeGuard($compile);
  const validator = compile(schema);
  return function (x: unknown) {
    if (validator(x)) {
      return x;
    } else {
      throw new Error("Cannot convert");
    }
  };
}

export function makeValidator<T>(schema: any) {
  return function (x: unknown): x is T {
    const validate = ajv.compile(schema);
    const result = validate(x);
    if (validate.errors) {
      console.log({input: x, errors: validate.errors, schema });
    }
    return result;
  }
}

export function upsert<T>(
  object: PartialWithId<T>,
  tableName: string,
  cols: string[],
  db: AppSqlApi
) {
  const record = Object.fromEntries(Object.entries(object));
  const dataCols = cols.filter((x) => x !== defaultIdName);
  const rowData: RowData = {};
  for (const column of dataCols) {
    const nativeVal = record[column];
    if (nativeVal === undefined) {
      continue;
    }
    const sqlVal = nativeToSql(nativeVal);
    rowData[column] = sqlVal;
  }
  const op = new UpsertTableDataOp(tableName, {
    [object.id]: rowData,
  });
  db.applyLocalOp(op);
}

export function getSqlTable<T extends ObjectJSONSchema>(
  tableName: string,
  schema: T
): TableDef {
  const columns: ColDef[] = [];
  for (const p of Object.keys(schema.properties)) {
    if (p === defaultIdName) {
      continue;
    } else {
      const jsType = schema.properties[p].type;
      const colType = jsTypeToSqlType(jsType);
      columns.push({ name: p, type: colType });
    }
  }
  return tableDef(tableName, columns);
}

export function createTableOp<T extends ObjectJSONSchema>(
  tableName: string,
  schema: T
): CreateTableOp {
  const columns: ColDef[] = [];
  for (const p of Object.keys(schema.properties)) {
    if (p === defaultIdName) {
      continue;
    } else {
      const jsType = schema.properties[p].type;
      const colType = jsTypeToSqlType(jsType);
      columns.push({ name: p, type: colType });
    }
  }
  return new CreateTableOp(tableName, columns);
}

export function tableDef(table: string, columns: ColDef[]): TableDef {
  return { table, columns, type: "table" };
}

export function viewDef(name: string, query: string): ViewDef {
  return { name, query, type: "view" };
}

export interface TableDef {
  type: "table";
  table: string;
  columns: ColDef[];
}

export interface ViewDef {
  type: "view";
  name: string;
  query: string;
}

export type SchemaDef = TableDef | ViewDef;
