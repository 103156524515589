import { SqlStateDb } from "./app-db";
import { SqlState } from "./sql-state";

export class DbSqlStateStore {
  constructor(private db: SqlStateDb) {}
  async set(id: string, state: SqlState, timestamp: number) {
    await this.db.sqlState.put({ id, ...state });
    await this.db.sqlStateTimestamps.put({ id, timestamp });
  }
  async get(id: string) {
    return this.db.sqlState.get(id);
  }
  async getTimestamp(id: string) {
    const record = await this.db.sqlStateTimestamps.get(id);
    return record?.timestamp;
  }
}
