import { Launch } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";

export function LinkAction({ url }: { url: string }) {
  return (
    <Button
      variant="outlined"
      endIcon={<Launch />}
      size="small"
      component="a"
      href={url}
      target="_blank"
    >
      Link
    </Button>
  );
}
