import { Box, TextField } from "@mui/material";
import React, { useState } from "react";

export function ChatInput({ onSubmit }: { onSubmit: (input: string) => void }) {
  const [input, setInput] = useState("");
  return (
    <Box width="100%">
      <TextField
        autoFocus
        multiline
        value={input}
        onChange={(e) => setInput(e.target.value)}
        type="text"
        fullWidth
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onSubmit(input);
            setInput("");
            e.preventDefault();
          }
        }}
      />
    </Box>
  );
}
