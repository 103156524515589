import React from "react";
import { useFileStats } from "./hooks";

export function FileStats() {
  const stats = useFileStats();
  if (!stats) {
    return <>Loading...</>;
  }
  const { count, countNeedsPull } = stats;
  const percentNeedsPull = count > 0 ? 100 * (countNeedsPull / count) : 0;
  return (
    <table>
      <tbody>
        <tr>
          <td>File Count:</td>
          <td>{count}</td>
        </tr>
        <tr>
          <td>Needs Pull:</td>
          <td>
            {countNeedsPull} ({percentNeedsPull.toFixed(0)}%)
          </td>
        </tr>
      </tbody>
    </table>
  );
}
