import { Button, Stack } from "@mui/material";
import React from "react";
import { useFpApi } from "./notes-hooks";
import {
  Video,
  addYouTubeVideoNote,
  getLikedVideosNoNotes,
  skipYouTubeVideoNote,
} from "./youtube-sync";

export function SyncView() {
  return (
    <Stack>
      <YoutubeSync />
    </Stack>
  );
}

function YoutubeSync() {
  const [results, setResults] = React.useState<Video[]>([]);
  const fpApi = useFpApi(false);
  return (
    <Stack>
      <YoutubeAuthButton onClick={setResults} />
      {results.map((result, i) => (
        <div key={i}>
          <Button onClick={() => addYouTubeVideoNote(fpApi, result)}>
            Add
          </Button>
          <Button onClick={() => skipYouTubeVideoNote(fpApi, result)}>
            Skip
          </Button>
          <a href={`https://www.youtube.com/watch?v=${result.id}`}>
            {result.likedDate.toLocaleDateString()}
            {" - "}
            {result.title}
          </a>
        </div>
      ))}
    </Stack>
  );
}

function YoutubeAuthButton({
  onClick,
}: {
  onClick: (results: Video[]) => void;
}) {
  const fpApi = useFpApi(false);
  const handle = async () => {
    const vids = await getLikedVideosNoNotes(fpApi);
    onClick(vids);
  };
  return <Button onClick={handle}>Auth</Button>;
}
