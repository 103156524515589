import { FromSchema } from "json-schema-to-ts";
import { BindParams, QueryExecResult, SqlValue } from "sql.js";
import { AppSqlApi } from "../../data/app-sql-api";
import { defaultIdName } from "../../data/conflict-free-db/core";
import { createTableOp, makeValidator, rowToNative, upsert } from "./schema";

const dbObjectSchema = {
  title: "DbObject",
  type: "object",
  properties: {
    [defaultIdName]: {
      type: "string",
    },
    title: {
      type: "string",
    },
    text: {
      type: "string",
    },
    visibility: {
      type: "string",
      enum: ["visible", "trashed", "internal"],
    },
    contentType: {
      type: "string",
    },
    createdAt: {
      type: "integer",
    },
    modifiedAt: {
      type: "integer",
    },
    objectType: {
      type: "string",
      enum: ["note", "file"],
    },
    isStarred: {
      type: "boolean",
    },
    defaultViewId: {
      type: "string",
    },
  },
  additionalProperties: false,
  required: [defaultIdName],
} as const;
export type DbObject = FromSchema<typeof dbObjectSchema>;
export type ComputedDbObject = DbObject;
const isDbObject = makeValidator<DbObject>(dbObjectSchema);

export function initializeDbObjects(
  db: AppSqlApi,
  tableName: string,
  visibleViewName: string,
  externalViewName: string
) {
  const createTable = createTableOp(tableName, dbObjectSchema);
  db.applyLocalOp(createTable);
  db.queryShared(
    `CREATE INDEX IF NOT EXISTS ${tableName}_visibility ON ${tableName}(visibility)`
  );
  db.queryShared(
    `CREATE INDEX IF NOT EXISTS ${tableName}_modifiedAt ON ${tableName}(modifiedAt)`
  );
  db.queryShared(
    `CREATE INDEX IF NOT EXISTS ${tableName}_createdAt ON ${tableName}(createdAt)`
  );
  db.queryShared(`
    DROP VIEW IF EXISTS ${visibleViewName};
    CREATE VIEW ${visibleViewName} AS
      SELECT * FROM ${tableName} WHERE visibility IS NULL OR visibility = "visible";
  `);
  db.queryShared(`
    DROP VIEW IF EXISTS ${externalViewName};
    CREATE VIEW ${externalViewName} AS
      SELECT * FROM ${tableName} WHERE visibility IS NULL OR visibility = "visible" OR visibility = "trashed";
  `);
  db.queryShared(
    `CREATE INDEX IF NOT EXISTS ${tableName}_isStarred ON ${tableName}(isStarred)`
  );
}
const dbObjectRowToNative = rowToNative(dbObjectSchema);
const defaultGetTime = () => new Date().getTime();
export class DbObjects {
  constructor(
    readonly db: AppSqlApi,
    readonly tableName = "objects",
    readonly visibleViewName = "visibleObjects",
    readonly externalViewName = "externalObjects",
    readonly imagesViewName = "imageAttachments",
    readonly getTime = defaultGetTime
  ) {}
  initialize() {
    initializeDbObjects(
      this.db,
      this.tableName,
      this.visibleViewName,
      this.externalViewName
    );
  }
  get(id: string): ComputedDbObject | undefined {
    const query = `
      SELECT obj.*
      FROM ${this.tableName} AS obj
      WHERE obj.id = ?`;
    const params = [id];
    const results = this.db.queryShared(query, params);
    const objects = this.rowsToObjects(results);
    return objects[0];
  }
  add(object: DbObject) {
    const createdAt = this.getTime();
    this.upsert({ ...object, createdAt });
  }
  update(update: DbObject) {
    this.upsert(update);
  }
  queryPlain(sql: string, params?: BindParams | undefined) {
    const results = this.db.queryShared(sql, params);
    return this.rowsToObjects(results);
  }
  queryShared(sql: string, params?: BindParams | undefined) {
    return this.db.queryShared(sql, params);
  }
  listObjects() {
    const sql = `
      SELECT obj.*
      FROM objects AS obj
      ORDER BY obj.modifiedAt DESC
    `;
    const results = this.queryPlain(sql);
    return results;
  }
  touch(id: string) {
    const modifiedAt = this.getTime();
    this.upsert({ id, modifiedAt });
  }
  private rowsToObjects(results: QueryExecResult[]): ComputedDbObject[] {
    const [result] = results;
    if (!result || results.length === 0) {
      return [];
    }
    const objects: DbObject[] = [];
    const columns = result.columns;
    for (const values of result.values) {
      const object = this.rowToObject(columns, values);
      if (object) {
        objects.push(object);
      }
    }
    return objects;
  }
  private rowToObject(
    columns: string[],
    values: SqlValue[]
  ): ComputedDbObject | undefined {
    const object = dbObjectRowToNative(columns, values);
    if (isDbObject(object)) {
      return { ...object };
    } else {
      return;
    }
  }
  private upsert(object: DbObject) {
    const modifiedAt = this.getTime();
    const cols = Object.keys(dbObjectSchema.properties);
    upsert({ ...object, modifiedAt }, this.tableName, cols, this.db);
  }
}
