import { Download } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { usePullFile } from "../../../hooks";
import { ObjectDetails } from "../../fp-api";

export function DownloadAction({ object }: { object: ObjectDetails }) {
  const [pullRequested, setPullRequested] = useState(false);
  const { pulled } = usePullFile(object.id, pullRequested);
  const loading = pullRequested && !pulled;
  const buttonRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (pullRequested) {
      buttonRef.current?.click();
    }
  }, [pullRequested]);
  return (
    <Box sx={{ position: "relative" }}>
      <Button
        variant="outlined"
        disabled={loading}
        endIcon={<Download />}
        size="small"
        component="a"
        href={`/files/${object.id}`}
        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
          if (!pulled && !loading) {
            setPullRequested(true);
            e && e.preventDefault();
          }
        }}
        download={object.title}
      >
        Download
      </Button>
    </Box>
  );
}
