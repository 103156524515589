import { FileData } from "../file-state";
import { Logger } from "../logging";
import throttledQueue from "throttled-queue";
import {
  BatchPullRequest,
  BatchPullResponse,
  PullClient,
  pullFailedResponse,
  PullRequest,
  PullResponse,
  pullSucceededResponse,
} from "../pull";
import {
  GetContent,
  getContentRequest,
  GetMetadata,
  getMetadataRequest,
} from "./google-drive-api";
import { idPrefix } from "./google-drive-logger";
import { GoogleDriveMetadata, toFileMetadata } from "./google-drive-metadata";

function contentChanged(
  data: FileData | undefined,
  metadata: GoogleDriveMetadata
) {
  if (!data) {
    return true;
  }
  return data.metadata.md5Checksum !== metadata.md5Checksum;
}

async function contentChangeResp(
  request: PullRequest,
  metadata: GoogleDriveMetadata,
  getContent: GetContent
): Promise<PullResponse> {
  const getContentResp = await getContent(
    getContentRequest(request.id, metadata.mimeType)
  );
  if (getContentResp.result === "failure") {
    return pullFailedResponse(request.id, getContentResp.error);
  }
  return pullSucceededResponse(request.id, {
    metadata: toFileMetadata(metadata),
    content: getContentResp.content,
  });
}

function metadataChangeResp(
  request: PullRequest,
  metadata: GoogleDriveMetadata
): PullResponse {
  return pullSucceededResponse(request.id, {
    metadata: toFileMetadata(metadata),
    content: request.data?.content ?? new ArrayBuffer(0),
  });
}

async function gdrivePullSingle(
  getMetadata: GetMetadata,
  getContent: GetContent,
  req: PullRequest,
  logger: Logger
): Promise<PullResponse> {
  logger = idPrefix(logger, req.id);
  const response = await getMetadata(getMetadataRequest(req.id));
  if (response.result === "failure") {
    logger(`Pull failed: ${response.error}`);
    return pullFailedResponse(req.id, response.error);
  }
  const metadata = response.metadata;
  if (contentChanged(req.data, metadata)) {
    logger("Content change");
    return contentChangeResp(req, metadata, getContent);
  } else {
    logger("Metadata-only change");
    return metadataChangeResp(req, metadata);
  }
}

export function gdrivePullClient(
  getMetadata: GetMetadata,
  getContent: GetContent,
  logger: Logger
): PullClient {
  const throttle = throttledQueue(80, 1000, true);
  return async function (
    request: BatchPullRequest
  ): Promise<BatchPullResponse> {
    const promises: Promise<PullResponse>[] = [];
    for (const req of request.requests) {
      const promise = throttle(() =>
        gdrivePullSingle(getMetadata, getContent, req, logger)
      );
      promises.push(promise);
    }
    const resps = await Promise.all(promises);
    return { source: request.source, responses: resps };
  };
}
