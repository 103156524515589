import { Image, UploadFile, VolumeUp } from "@mui/icons-material";
import { Button, ButtonGroup } from "@mui/material";
import React, { useRef } from "react";

export interface FileInputProps {
  onAdd: (file: File) => void;
  accept?: string;
}
export function FileInput({ onAdd, accept }: FileInputProps) {
  return (
    <ButtonGroup variant="outlined" size="small">
      <FileInputButton
        onAdd={onAdd}
        accept={accept}
        label="Add File"
        icon={<UploadFile />}
      />
      <FileInputButton
        onAdd={onAdd}
        accept="image/*"
        label="Add image"
        icon={<Image />}
      />
      <FileInputButton
        onAdd={onAdd}
        accept="audio/*"
        label="Add audio"
        icon={<VolumeUp />}
      />
    </ButtonGroup>
  );
}

function FileInputButton({
  onAdd,
  accept,
  label,
  icon,
}: {
  onAdd: (file: File) => void;
  accept?: string;
  label?: string;
  icon: React.ReactNode;
}) {
  const ref = useRef<HTMLInputElement>(null);
  label = label ?? "Pick File";
  return (
    <Button startIcon={icon} component="label">
      {label}
      <input
        ref={ref}
        type="file"
        hidden
        multiple={false}
        accept={accept}
        onChange={async (e) => {
          const files = e.target.files;
          if (!files || files.length === 0) {
            return;
          }
          const file = files[0];
          onAdd(file);
          e.target.value = "";
        }}
      />
    </Button>
  );
}
