import React, { useEffect } from "react";
import { useSource, useSourceList } from "./hooks";

function SourceOption({ id }: { id: string }) {
  const source = useSource(id);
  if (!source) {
    return <></>;
  }
  return <option value={id}>{source.name}</option>;
}

export function SourceSelector({
  onChange,
}: {
  onChange?: (s: string) => void;
}) {
  const sources = useSourceList(false);
  useEffect(() => {
    if (sources?.length && onChange) {
      onChange(sources[0]);
    }
  }, [sources, onChange]);
  if (!sources) {
    return (
      <select disabled>
        <option>Loading...</option>
      </select>
    );
  }
  const options = sources.map((id) => <SourceOption id={id} key={id} />);
  return (
    <select
      onChange={(e) => {
        onChange && onChange(e.target.value);
      }}
    >
      {options}
    </select>
  );
}
