import Dexie, { DexieOptions, Table } from "dexie";
import { FileState } from "./file-state";
import { GoogleCredentials } from "./google-drive/google-drive-auth";
import { GDriveChangeWatcherState } from "./google-drive/google-drive-change-watcher";
import { CrawlerState } from "./google-drive/google-drive-crawler-state";
import { LogEntry } from "./logging";
import { SourceState } from "./source-state";
import { SqlState } from "./sql-state";

export type FileRecord = FileState & {
  id: string;
  needsPull: number;
  needsPush: number;
  needsCrawl: number;
  contentType: string | undefined;
};
export type FileStateDb = Dexie & {
  fileState: Table<FileRecord>;
};

export type SourceRecord = SourceState & { id: string; type: string };
export type SourceStateDb = Dexie & {
  sourceState: Table<SourceRecord>;
};

export type GoogleAuthRecord = GoogleCredentials & { id: string };
export type GoogleAuthDb = Dexie & {
  googleAuth: Table<GoogleAuthRecord>;
};

export type GDriveIdRecord = {
  id: string;
};
export type GDriveIdDb = Dexie & {
  gdriveId: Table<GDriveIdRecord>;
};

export type GDriveChangeWatcherRecord = GDriveChangeWatcherState & {
  id: string;
};
export type GDriveChangeWatcherDb = Dexie & {
  gdriveChangeWatcher: Table<GDriveChangeWatcherRecord>;
};

export type LogRecord = LogEntry & { id: string };
export type LogDb = Dexie & {
  log: Table<LogRecord>;
};

export type GDriveCrawlerDb = Dexie & {
  gdriveCrawler: Table<CrawlerState>;
};

export type SqlStateRecord = SqlState & { id: string };
export type SqlStateTimestampRecord = { id: string; timestamp: number };
export type SqlStateDb = Dexie & {
  sqlState: Table<SqlStateRecord>;
  sqlStateTimestamps: Table<SqlStateTimestampRecord>;
};

export type PrivateTableVersionRecord = { tag: string; version: number };
export type PrivateTableVersionDb = Dexie & {
  privateTableVersions: Table<PrivateTableVersionRecord>;
};

export const defaultDbName = "app-db";
export class AppDb
  extends Dexie
  implements
    FileStateDb,
    SourceStateDb,
    GoogleAuthDb,
    LogDb,
    GDriveCrawlerDb,
    PrivateTableVersionDb
{
  fileState!: Table<FileRecord>;
  sourceState!: Table<SourceRecord>;
  googleAuth!: Table<GoogleAuthRecord>;
  gdriveId!: Table<GDriveIdRecord>;
  gdriveChangeWatcher!: Table<GDriveChangeWatcherRecord>;
  log!: Table<LogRecord>;
  gdriveCrawler!: Table<CrawlerState>;
  sqlState!: Table<SqlStateRecord>;
  sqlStateTimestamps!: Table<SqlStateTimestampRecord>;
  privateTableVersions!: Table<PrivateTableVersionRecord>;
  constructor(dbName: string = defaultDbName, options?: DexieOptions) {
    super(dbName, options);
    this.version(23).stores({
      fileState:
        "id, needsPull, needsPush, source, [source+needsPull], contentType, [source+needsPush], [source+needsCrawl]",
      sourceState: "id, type",
      googleAuth: "id",
      gdriveId: "id",
      gdriveChangeWatcher: "id",
      log: "id, timestamp",
      gdriveCrawler: "id",
      sqlState: "id",
      sqlStateTimestamps: "id",
      privateTableVersions: "tag",
    });
  }
}

export async function deleteDb(appDb: AppDb) {
  await appDb.delete();
  const reg = await navigator.serviceWorker.getRegistration();
  if (reg) {
    await reg.unregister();
  }
  window.location.reload();
}

export async function initializeDb() {
  const db = new AppDb(defaultDbName);
  return db;
}
