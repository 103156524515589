import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { FpApi, ObjectDetails } from "../fp-api";
import { ObjectTitle } from "./ObjectTitle";
import { ObjectActions } from "./actions/ObjectActions";

export function ObjectDetailHeader({
  object,
  api,
}: {
  object: ObjectDetails;
  api: FpApi;
}) {
  return (
    <Container
      disableGutters
      sx={{
        display: "flex",
        flexDirection: "column",
        paddingLeft: 1,
        paddingRight: 1,
      }}
    >
      <ObjectTitle object={object} api={api} />
      <Box sx={{ marginBottom: 1, marginTop: 1 }}>
        <ObjectActions object={object} api={api} />
      </Box>
      <Typography variant="caption">{formatDate(object.createdAt)}</Typography>
    </Container>
  );
}

function formatDate(d: Date) {
  return d.toLocaleDateString("en-US", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
}
