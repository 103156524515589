import { FromSchema } from "json-schema-to-ts";
import { BindParams, SqlValue } from "sql.js";
import { AppSqlApi } from "../../data/app-sql-api";
import { defaultIdName } from "../../data/conflict-free-db/core";
import { DeleteRowsOp } from "../../data/conflict-free-db/operations";
import { createTableOp, makeValidator, rowToNative, upsert } from "./schema";

export const dbAttachmentSchema = {
  title: "DbAttachment",
  type: "object",
  properties: {
    [defaultIdName]: {
      type: "string",
    },
    objectId: {
      type: "string",
    },
    fileId: {
      type: "string",
    },
    contentType: {
      type: "string",
    },
    createdAt: {
      type: "integer",
    },
  },
  additionalProperties: false,
  required: [defaultIdName],
} as const;
export type DbAttachment = FromSchema<typeof dbAttachmentSchema>;
const dbAttachmentRowToNative = rowToNative(dbAttachmentSchema);
const isDbAttachment = makeValidator<DbAttachment>(dbAttachmentSchema);
export function initializeAttachments(
  db: AppSqlApi,
  tableName: string,
  imageTableName: string
) {
  const createTable = createTableOp(tableName, dbAttachmentSchema);
  db.applyLocalOp(createTable);
  db.queryShared(`DROP VIEW IF EXISTS ${imageTableName}`);
  db.queryShared(`CREATE VIEW ${imageTableName} AS
  SELECT
    *, min(createdAt)
  FROM ${tableName}
  WHERE contentType LIKE 'image/%'
  GROUP BY objectId`);
  db.queryShared(
    `CREATE INDEX IF NOT EXISTS ${tableName}_objectId ON ${tableName}(objectId)`
  );
  db.queryShared(
    `CREATE INDEX IF NOT EXISTS ${tableName}_fileId ON ${tableName}(fileId)`
  );
  db.queryShared(
    `CREATE INDEX IF NOT EXISTS ${tableName}_contentType ON ${tableName}(contentType)`
  );
}
export interface AttachmentListItem {
  readonly attachmentId: string;
  readonly title: string;
  readonly contentType: string;
  readonly fileId?: string;
  readonly objectId?: string;
  readonly objectTitle?: string;
  readonly createdAt: Date;
}
export class DbAttachments {
  constructor(
    private db: AppSqlApi,
    readonly tableName = "attachments",
    readonly imageTableName = "imageAttachments",
    readonly getTime = () => new Date().getTime()
  ) {}
  initialize() {
    initializeAttachments(this.db, this.tableName, this.imageTableName);
  }
  add(attachment: DbAttachment) {
    this.upsert({ ...attachment, createdAt: this.getTime() });
  }
  delete(id: string) {
    this.db.applyLocalOp(new DeleteRowsOp(this.tableName, [id]));
  }
  get(id: string) {
    const [results] = this.db.queryShared(
      `SELECT * FROM ${this.tableName} WHERE id = ?`,
      [id]
    );
    if (!results || results.values.length === 0) {
      return;
    }
    const cols = results.columns;
    const row = results.values[0];
    return this.rowToAttachment(cols, row);
  }
  queryPlain(sql: string, params?: BindParams | undefined) {
    const [results] = this.db.queryShared(sql, params);
    if (!results) {
      return [];
    }
    const items: AttachmentListItem[] = [];
    const cols = results.columns;
    for (const row of results.values) {
      const dbAttachment = this.rowToAttachment(cols, row);
      if (!dbAttachment) {
        continue;
      }
      items.push({
        attachmentId: dbAttachment.id,
        title: row[0]?.toLocaleString() ?? "Untitled Attachment",
        contentType: dbAttachment.contentType ?? "application/octet-stream",
        fileId: dbAttachment.fileId,
        objectId: dbAttachment.objectId,
        objectTitle: row[1]?.toLocaleString(),
        createdAt: new Date(dbAttachment.createdAt ?? 0),
      });
    }
    return items;
  }
  attachmentsForObject(id: string): AttachmentListItem[] {
    const sql = `
      SELECT fileObj.title, obj.title, att.*
      FROM attachments AS att
      JOIN objects AS obj ON obj.id = att.objectId
      JOIN objects AS fileObj ON fileObj.id = att.fileId
      WHERE
        att.objectId = ?
      ORDER BY obj.modifiedAt DESC
    `;
    return this.queryPlain(sql, [id]);
  }
  private rowToAttachment(
    columns: string[],
    values: SqlValue[]
  ): DbAttachment | undefined {
    const object = dbAttachmentRowToNative(columns, values);
    if (isDbAttachment(object)) {
      return object;
    } else {
      return;
    }
  }
  private upsert(attachment: DbAttachment) {
    const cols = Object.keys(dbAttachmentSchema.properties);
    upsert(attachment, this.tableName, cols, this.db);
  }
}
