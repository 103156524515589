import { defaultLogger, Logger } from "../logging";
import {
  BatchPushRequest,
  BatchPushResponse,
  PushRequest,
  PushResponse,
} from "../push";
import {
  Create,
  gdriveCreateRequest,
  gdriveUpdateRequest,
  gdriveUploadRequest,
  UpdateMetadata,
  Upload,
} from "./google-drive-api";
import { idPrefix } from "./google-drive-logger";
import { gdriveMetadata } from "./google-drive-metadata";

async function pushSingleRequest(
  request: PushRequest,
  create: Create,
  upload: Upload,
  updateMetadata: UpdateMetadata,
  logger: Logger
): Promise<PushResponse> {
  const {
    id,
    revision,
    remoteExists,
    data,
    metadataModified,
    contentModified,
  } = request;
  logger = idPrefix(logger, id);
  logger("Pushing");
  const remoteMetadata = gdriveMetadata(id, data);
  if (!remoteExists) {
    logger("Does not exist, creating");
    const createReq = gdriveCreateRequest(id, remoteMetadata);
    const resp = await create(createReq);
    if (resp.type === "failed") {
      logger(`Failed to create: ${resp.error}`);
      return {
        id,
        success: false,
        revision,
        error: resp.error,
      };
    }
  }
  if (metadataModified && !contentModified) {
    logger(`Only metadata modified, updating`);
    const updateReq = gdriveUpdateRequest(id, remoteMetadata);
    const resp = await updateMetadata(updateReq);
    if (resp.type === "failed") {
      logger(`Metadata update failed: ${resp.error}`);
      return {
        id,
        success: false,
        revision,
        error: resp.error,
      };
    } else {
      return {
        id,
        success: true,
        revision,
      };
    }
  }
  logger(`Content changed, uploading data`);
  const uploadReq = gdriveUploadRequest(data.content, remoteMetadata);
  const resp = await upload(uploadReq);
  if (resp.type === "failed") {
    logger(`Content upload failed: ${resp.error}`);
    return {
      id,
      success: false,
      revision,
      error: resp.error,
    };
  }
  return {
    id,
    success: true,
    revision,
  };
}

export function gdrivePushClient(
  create: Create,
  upload: Upload,
  updateMetadata: UpdateMetadata,
  logger: Logger = defaultLogger
) {
  return async function (
    request: BatchPushRequest
  ): Promise<BatchPushResponse> {
    const responses: PushResponse[] = [];
    for (const req of request.requests) {
      const resp = await pushSingleRequest(
        req,
        create,
        upload,
        updateMetadata,
        logger
      );
      responses.push(resp);
    }
    return { responses };
  };
}
