import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import React from "react";
import { createRoot } from "react-dom/client";
import "react-edit-text/dist/index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./app.css";
import { initializeDb } from "./data/app-db";
import { initializeFs } from "./data/app-file-system";
import { initializeSqlDbs } from "./data/app-sql-api";
import { initializeAuth } from "./data/auth";
import { initializeBackgroundJobRunner } from "./data/background-jobs/background-jobs";
import { initializeController } from "./data/controller";
import { initializeLogController } from "./data/logging";
import { initializeServiceWorker } from "./service-worker-client";
import { DebugView } from "./ui/DebugView";
import { Layout } from "./ui/Layout";
import { LoadErrorScreen } from "./ui/LoadErrorScreen";
import { Main } from "./ui/Main";
import { NotFound } from "./ui/NotFound";
import { ShareTarget } from "./ui/ShareTarget";
import { SourceListView } from "./ui/SourceListView";
import { NotesApp } from "./ui/notes/NotesApp";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Layout />}>
          <Route path="sources" element={<SourceListView />} />
          <Route path="debug" element={<DebugView />} />
          <Route path="share" element={<ShareTarget />} />
          <Route path="notes/*" element={<NotesApp />} />
          <Route path="" element={<Main />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const rootElementId = "root";
const fileStoreChannel = "file-store-channel";
const bgJobLock = "background-job-lock";

async function initialize() {
  await initializeServiceWorker();
  const db = await initializeDb();
  const logController = initializeLogController(db);
  const logger = logController.getLogger();
  const auth = initializeAuth(db, logger);
  const fs = await initializeFs(db, auth, fileStoreChannel, logger);
  const sqlDbs = await initializeSqlDbs(db, fs, logger);
  initializeBackgroundJobRunner(bgJobLock, db, auth, fs, sqlDbs, logger);
  const controller = await initializeController(db, fs, auth, sqlDbs);
  const container = document.getElementById(rootElementId);
  if (!container) {
    throw Error("container not found");
  }
  const root = createRoot(container);
  root.render(<App />);
  window.controller = controller;
}
initialize().catch((error) => {
  const container = document.body;
  const root = createRoot(container);
  root.render(<LoadErrorScreen message={error.message} />);
});
