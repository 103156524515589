import SearchIcon from "@mui/icons-material/Search";
import { InputBase, alpha, styled } from "@mui/material";
import React from "react";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    width: "100%",
  },
}));

function isEnter(key: string) {
  return key === "Enter";
}

export function SearchBar({
  onChange,
  onClick,
  value,
  inputRef,
  onEnter,
}: {
  onClick: () => void;
  onChange: (value: string) => void;
  onEnter: () => void;
  value: string;
  inputRef: React.RefObject<HTMLInputElement>;
}) {
  return (
    <Search onClick={onClick}>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Search or create…"
        inputProps={{
          "aria-label": "search",
        }}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={(e) => isEnter(e.key) && onEnter()}
        sx={{ width: "100%" }}
        value={value}
        inputRef={inputRef}
        type="text"
        spellCheck={false}
      />
    </Search>
  );
}
